import axios from 'axios';

import { getEnvironment } from '../common/constants';

export const baseURL = getEnvironment(process.env.REACT_APP_ENV).api;

const instance = axios.create({
    baseURL,
    // Below line is for testing production configuration on local env. Uncomment to fetch data from production
    // baseURL: 'https://api.miloo.co/api/',
});

export default instance;
