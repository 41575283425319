import * as types from '../constants/actionTypes';

export const orderResultSetId = (id) => ({
    type: types.ORDER_RESULT_SET_ID,
    payload: id,
});

export const orderResultClear = () => ({ type: types.ORDER_RESULT_CLEAR });

export const orderResultGetOrderData = (id) => ({
    type: types.ORDER_RESULT_GET_DATA,
    payload: id,
});

export const orderResultSetData = (data) => ({
    type: types.ORDER_RESULT_SET_DATA,
    payload: data,
});
