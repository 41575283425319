import * as types from '../constants/actionTypes';

export const vehiclesGet = () => ({ type: types.VEHICLES_GET });

export const vehiclesSet = (vehicles) => ({
    type: types.VEHICLES_SET,
    payload: vehicles,
});

export const vehiclesClear = () => ({ type: types.VEHICLES_CLEAR });
