import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#28282d',
        },
        inputBg: '#eeeef3',
        inputUnderline: {
            active: '#28282d',
        },
        label: '#868593',
        btnMain: {
            main: '#E03C31',
            dark: '#ba000d',
            light: '#f44e33',
            contrastText: '#fff',
        },
        btnSecondary: {
            main: '#dddde8',
            light: '#cfcfcf',
            contrastText: '#28282d',
        },
    },
    overrides: {
        MuiAutocomplete: {
            option: {
                '&[aria-selected="true"]': {
                    backgroundColor: '#ffffff',
                },
            },
        },
    },
});
