import { Link } from 'react-router-dom';
import arrowIcon from '../../assets/images/arrow-left-24.svg';
import styled from 'styled-components';

export const BackLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    height: 20px;
    color: #dc2d2d;
    font-size: 14px;
    text-decoration: none;
    &:before {
        content: '';
        display: inline-block;
        background: url(${arrowIcon}) 50% no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }
`;
