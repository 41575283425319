import * as types from '../constants/actionTypes';

export const productGetProducts = (type) => ({
    type: types.PRODUCT_GET_PRODUCTS,
    payload: type,
});

export const productSetProducts = (data) => ({
    type: types.PRODUCT_SET_PRODUCTS,
    payload: data,
});

export const productAddToCart = (
    id,
    quantity = 1,
    attachTo = null,
    configuratorOptions,
    isQuantity,
    type = null,
) => ({
    type: types.PRODUCT_ADD_TO_CART,
    payload: { id, quantity, attachTo, configuratorOptions, isQuantity, type },
});

export const productSelect = (productId) => ({
    type: types.PRODUCT_SELECT,
    payload: productId,
});

export const notificationShow = (id, quantity = 1, attachTo = null) => ({
    type: types.NOTIFICATION,
    payload: { id, quantity, attachTo },
});

export const notificationHide = () => ({
    type: types.NOTIFICATION_RESET,
});

export const reviewsGetProduct = (id) => ({
    type: types.GET_REVIEWS_PRODUCTS,
    payload: id,
});

export const reviewsSetProduct = (reviews) => ({
    type: types.SET_REVIEWS_PRODUCTS,
    payload: reviews,
});

export const expiviError = (isError) => ({
    type: types.SET_EXPIVI_ERROR,
    payload: isError,
});

//Error
export const setProductErrors = (errors) => ({
    type: types.SET_PRODUCT_ERROR,
    payload: errors,
});

//Accessories-service
export const getProductSubcategories = (params) => ({
    type: types.GET_PRODUCT_SUBCATEGORIES,
    payload: params,
});
export const setProductSubcategories = (data) => ({
    type: types.SET_PRODUCT_SUBCATEGORIES,
    payload: data,
});

//Configurator
export const getConfiguratePrices = (data) => ({
    type: types.GET_CONFIGURATION_PRICES,
    payload: data,
});
export const setConfigurateCreate = () => ({
    type: types.SET_CONFIGURATION_CREATE,
});
export const setUpdateConfigurate = (params) => ({
    type: types.SET_CONFIGURATION_UPDATE,
    payload: params,
});
