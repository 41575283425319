import styled from 'styled-components';

export const TitleSecondary = styled.h3`
    font-weight: 100;
    font-size: 34px;
    letter-spacing: -0.5px;
    color: #28282d;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    @media (max-width: 800px) {
        font-size: 20px;
    }
    @media (max-width: 767px) {
        text-align: start;
    }
`;
