import * as types from '../constants/actionTypes';

export const getQuotationResult = (data) => ({
    type: types.GET_QUOTATION_DATA,
    payload: data,
});
export const setQuotationResult = (data) => ({
    type: types.SET_QUOTATION_DATA,
    payload: data,
});
export const setResetDone = () => ({ type: types.SET_RESET_DONE });
export const setQuotationErrors = (data) => ({
    type: types.SET_QUOTATION_ERRORS,
    payload: data,
});
