import * as types from '../constants/actionTypes';

export const setEditConfiguration = (payload) => ({
    type: types.SET_EDIT_CONFIGURATION,
    payload,
});
export const setCartIdConfiguration = (payload) => ({
    type: types.SET_CART_ID_CONFIGURATION,
    payload,
});

export const getSpecialOffers = () => ({ type: types.GET_SPECIAL_OFFERS });
export const setSpecialOffers = (payload) => ({
    type: types.SET_SPECIAL_OFFERS,
    payload,
});
