import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { getProductRequest, setProduct } from '../actions';
import * as types from '../constants/actionTypes';

function* getProduct({ payload }) {
    try {
        yield put(getProductRequest());
        const { data } = yield call(Api.getProduct, payload);
        yield put(setProduct(data));
    } catch (e) {
        //error
    }
}

function* watchProductRefurbished() {
    yield takeLatest(types.GET_PRODUCT, getProduct);
}

export default function* watchRefurbishedProductDetails() {
    yield all([fork(watchProductRefurbished)]);
}
