import * as types from '../constants/actionTypes';

export const verifyEmailStart = (email) => ({
    type: types.VERIFY_EMAIL_START,
    payload: email,
});

export const verifyEmailSuccess = (data) => ({
    type: types.VERIFY_EMAIL_SUCCESS,
    payload: data,
});

export const verifyEmailError = (error) => ({
    type: types.VERIFY_EMAIL_ERROR,
    error,
});

export const verifyEmailClearError = () => ({
    type: types.VERIFY_EMAIL_ERROR,
    error: '',
});
