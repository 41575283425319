import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    data: [],
};

const vehiclesGet = (state) => ({ ...state, done: false });

const vehiclesSet = (state, action) => ({
    ...state,
    done: true,
    data: action.payload,
});

const vehiclesClear = (initialState) => initialState;

const vehiclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.VEHICLES_GET:
            return vehiclesGet(state);
        case types.VEHICLES_SET:
            return vehiclesSet(state, action);
        case types.VEHICLES_CLEAR:
            return vehiclesClear(initialState);
        default:
            return state;
    }
};

export default vehiclesReducer;
