import * as types from '../constants/actionTypes';

const initialState = {
    done: false,
    data: null,
};

const orderDetailsGet = (state) => ({ ...state, done: false });

const orderDetailsSet = (state, action) => ({
    ...state,
    done: true,
    data: action.payload,
});

const orderDetailsClear = (initialState) => initialState;

const orderDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ORDER_DETAILS_GET:
            return orderDetailsGet(state);
        case types.ORDER_DETAILS_SET:
            return orderDetailsSet(state, action);
        case types.ORDER_DETAILS_CLEAR:
            return orderDetailsClear(initialState);
        default:
            return state;
    }
};

export default orderDetailsReducer;
