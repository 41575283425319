import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    error: '',
    success: false,
    email: '',
};

const sendPasswordStart = (state, action) => ({
    ...state,
    done: false,
    ...action.payload,
});

const sendPasswordSuccess = (state, action) => ({
    ...state,
    ...action.payload,
});

const sendPasswordError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
    success: false,
});

export const sendNewPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_PASSWORD_START:
            return sendPasswordStart(state, action);
        case types.CHANGE_PASSWORD_SUCCESS:
            return sendPasswordSuccess(state, action);
        case types.CHANGE_PASSWORD_ERROR:
            return sendPasswordError(state, action);
        default:
            return state;
    }
};
