import * as types from '../constants/actionTypes';

export const changePasswordStart = (data) => ({
    type: types.CHANGE_PASSWORD_START,
    payload: data,
});

export const changePasswordSuccess = (data) => ({
    type: types.CHANGE_PASSWORD_SUCCESS,
    payload: data,
});

export const changePasswordError = (error) => ({
    type: types.CHANGE_PASSWORD_ERROR,
    error,
});

export const changePasswordClearError = () => ({
    type: types.CHANGE_PASSWORD_ERROR,
    error: '',
});
