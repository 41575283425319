import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    data: [],
};

const ordersClear = (initialState) => initialState;

const ordersGet = (state) => ({ ...state, done: false });

const ordersSet = (state, action) => ({
    ...state,
    done: true,
    data: action.payload,
});

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ORDERS_CLEAR:
            return ordersClear(initialState);
        case types.ORDERS_GET:
            return ordersGet(state);
        case types.ORDERS_SET:
            return ordersSet(state, action);
        default:
            return state;
    }
};

export default ordersReducer;
