import * as types from '../constants/actionTypes';

const initialState = {
    product: null,
    loading: false,
};

const getRequest = (state) => ({ ...state, loading: true });

const setProduct = (state, { payload }) => ({
    ...state,
    product: payload,
    loading: false,
});

const RefurbishedDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRODUCT:
            return getRequest(state);
        case types.SET_PRODUCT:
            return setProduct(state, action);
        default:
            return state;
    }
};

export default RefurbishedDetailReducer;
