import API from './apiCore';

export const registerUser = (data) => API.post('/users', data);

export const getUser = () => API.get('/users/me');

export const updateUser = (data) => API.put('/users/me', data);

export const updateUserImage = (data) => API.put('/users/me/img', data);

export const getCart = () => API.get('/carts');

export const addCartItem = (data) => API.post('/carts/', data);

export const updateCartItem = (id, data) =>
    API.put('/carts/{id}'.replace('{id}', id), data);

export const deleteCartItem = (id) =>
    API.delete('/carts/{id}'.replace('{id}', id));

export const configuratorPrices = (data) =>
    API.post('/carts/get-configurator-prices', data);

/*TODO Should rewrite for all device by type and add params take:100 for type accessories*/
export const getProducts = () =>
    API.get('/products', { params: { take: 500 } });

export const getProduct = (id) => API.get(`/products/${id}`);

export const getProductTypes = (query) =>
    API.get(`/product-types/${query}/product`);

export const getSubcategories = (offset = 0, limit = 100) =>
    API.get('/product-subcategories', { params: { offset, limit } });

export const getSubcategoriesRefurbished = (offset = 0, limit = 100) =>
    API.get('/product-subcategories/refurbished', {
        params: { offset, limit },
    });

export const getAllProductRefurbisheds = ({ lang }) =>
    API.get(`/refurbished-products?offset=0&limit=100&lang=${lang}`);

export const getProductRefurbisheds = ({
    offset = 0,
    limit = 20,
    subcategoryId,
}) =>
    API.get('/refurbished-products', {
        params: { offset, limit, subcategoryId },
    });

export const getProductSubcategories = ({
    offset = 0,
    limit = 100,
    subcategoryId,
}) =>
    API.get('/accessories-and-services', {
        params: { offset, limit, subcategoryId },
    });

export const getUserDictionary = () => API.get('/users/dictionary');

export const resendEmail = (data) => API.post('/auth/confirm/email', data);

export const login = (data) => API.post('/auth/login', data);

export const getOrder = (id) => API.get('/orders/{id}'.replace('{id}', id));

export const getOrders = (type) =>
    API.get('/orders', { params: { type: type } });

export const checkout = (data) => API.post('/orders/checkout', data);

export const specialOffers = (params) =>
    API.get(
        '/special-offers',
        !params ? {} : { headers: { 'Accept-Language': params } },
    );

export const checkoutIncognito = (data) =>
    API.post('/orders/checkout/incognito', data);

export const payForOrder = (id) =>
    API.post('/orders/{id}/pay'.replace('{id}', id));

export const payForOrderIncognito = (id) =>
    API.post('/orders/{id}/pay/incognito'.replace('{id}', id));

export const getOrederQuotation = (data) => API.post('/orders/quotation', data);

export const getOrederQuotationIncognito = (data) =>
    API.post('/orders/quotation/incognito', data);

export const createPreOrder = (data) => API.post('/pre-order-requests', data);

export const contactUs = (data) => API.post('/contact-us', data);

export const getPaymentClientKey = () => API.get('/stripe/card-wallet');

export const getVehicles = () => API.get('/vehicles');

export const getVehicle = (id) => API.get('/vehicles/{id}'.replace('{id}', id));

export const resetPasswordCode = (data) => API.post('/auth/code', data);

export const verifyCode = (data) => API.post('/auth/code/verify', data);

export const getExperienceCenters = () => API.get('/pick-up-center');

export const getTestDrivePoints = () => API.get('/test-drive');

export const getTestDriveInfo = () => API.get('/test-drive/page');

export const getWeekDay = (zipCode) => API.get(`/zipcodes/${zipCode}`);

export const getTimeSlotsCheckout = (zipcode, date) =>
    API.get('/delivery-time-slots', { params: { zipcode, date } });

export const getTestDriveTimeSlotsRequest = (date, testDriveCenter) =>
    API.get('/test-drive-applications', { params: { date, testDriveCenter } });

export const bookTestDrive = (data) =>
    API.post('/test-drive-applications', data);

export const sendMessageReview = (idProduct, text, rate) =>
    API.post(`/products/${idProduct}/reviews`, { text, rate });

export const subscribeToNewsletter = (data) =>
    API.post('/news-letter-subscriptions', data);

export const getDiscountProduct = (coupon, idProduct) =>
    API.post(`/coupons/${coupon}/apply-to-products/${idProduct}`);

export const getReviews = (id) => API.get(`/products/${id}/reviews`);

export const getCentersList = () => API.get('/find-us-locations');

export const getFaqItem = (typeFaq) =>
    API.get('/faq', { params: { type: typeFaq } });

export const getServiceHomeList = () => API.get('/service-at-home-blocks');

export const getPartnersContent = () => API.get('/find-us-locations');

export const getHomePage = () => API.get('/home-page');

export const getBlogPosts = (props) => {
    const {
        offset = 0,
        limit = 10,
        search,
        articleCategoryId = '',
        hashtags = '',
    } = props;
    return API.get('/articles', {
        params: {
            offset,
            limit,
            search,
            articleCategoryId,
            'hashtags[]': hashtags,
        },
    });
};

export const getBlogPost = (id) => API.get(`/articles/${id}`);

export const getBlogCategories = () => API.get('/article-categories');

export const getOdooInstanceIds = () => API.get('/pick-up-center');
