import * as types from '../constants/actionTypes';

export const profileClear = () => ({ type: types.PROFILE_CLEAR });

export const profileGetUser = () => ({ type: types.PROFILE_GET_USER_START });

export const profileGetUserSuccess = (user) => ({
    type: types.PROFILE_GET_USER_SUCCESS,
    payload: user,
});

export const profileGetUserError = (error) => ({
    type: types.PROFILE_GET_USER_ERROR,
    error,
});

export const profileSaveImage = (data) => ({
    type: types.PROFILE_SAVE_PHOTO,
    payload: data,
});

export const profileSaveImageSuccess = (data) => ({
    type: types.PROFILE_SAVE_PHOTO_SUCCESS,
    payload: data,
});

export const profileSaveImageError = (error) => ({
    type: types.PROFILE_SAVE_PHOTO_ERROR,
    error,
});

export const profileUpdateUser = (data) => ({
    type: types.PROFILE_UPDATE_USER_START,
    payload: data,
});

export const profileUpdateUserSuccess = (data) => ({
    type: types.PROFILE_UPDATE_USER_SUCCESS,
    payload: data,
});

export const profileUpdateUserError = (error) => ({
    type: types.PROFILE_UPDATE_USER_ERROR,
    error,
});

export const profileResetForm = (formType) => ({
    type: types.PROFILE_RESET_FORM,
    payload: formType,
});
