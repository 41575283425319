export const PRODUCT_GET_PRODUCTS = 'PRODUCT_GET_PRODUCTS';
export const PRODUCT_SET_PRODUCTS = 'PRODUCT_SET_PRODUCTS';
export const PRODUCT_SELECT = 'PRODUCT_SELECT';
export const PRODUCT_ADD_TO_CART = 'PRODUCT_ADD_TO_CART';
export const PRODUCT_ADD_TO_CART_OFFLINE = 'PRODUCT_ADD_TO_CART_OFFLINE';
export const NOTIFICATION = 'NOTIFICATION';
export const RELOAD_NOTIFICATION = 'RELOAD_NOTIFICATION';
export const NOTIFICATION_RESET = 'NOTIFICATION_RESET';
export const GET_REVIEWS_PRODUCTS = 'GET_REVIEWS_PRODUCTS';
export const SET_REVIEWS_PRODUCTS = 'SET_REVIEWS_PRODUCTS';

//Error
export const SET_PRODUCT_ERROR = 'SET_PRODUCT_ERROR';
export const SET_EXPIVI_ERROR = 'SET_EXPIVI_ERROR';

//Accessories-service
export const GET_PRODUCT_SUBCATEGORIES = 'GET_PRODUCT_SUBCATEGORIES';
export const SET_PRODUCT_SUBCATEGORIES = 'SET_PRODUCT_SUBCATEGORIES';

//Configuration
export const GET_CONFIGURATION_PRICES = 'GET_CONFIGURATION_PRICES';
export const SET_CONFIGURATION_PRICES = 'SET_CONFIGURATION_PRICES';
export const SET_CONFIGURATION_CREATE = 'SET_CONFIGURATION_CREATE';
export const SET_CONFIGURATION_UPDATE = 'SET_CONFIGURATION_UPDATE';
