import * as Sentry from '@sentry/react';

import React, { useCallback, useEffect } from 'react';

import Helmet from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import ScrollIntoView from '../common/components/ScrollIntoView/ScrollIntoView';
import { getDictionary } from '../features/login/redux/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const App = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const dictionaryGet = useCallback(() => {
        dispatch(getDictionary());
    }, [dispatch]);

    useEffect(() => {
        dictionaryGet();
    }, [dictionaryGet]);

    return (
        <Router>
            <Helmet>
                <html lang={i18n.language} />
            </Helmet>
            <ScrollIntoView>
                <Routes />
            </ScrollIntoView>
        </Router>
    );
};

export default Sentry.withProfiler(App);
