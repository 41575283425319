import styled from 'styled-components';

export const Subheader = styled.h3`
    // TODO: delete after changing all h3 on the website
    margin: 0;
    font-size: 40px;
    color: #2a2a2f;
    white-space: pre-wrap;
    strong {
        color: ${(props) => props.theme.colors.red_base};
    }
    @media (max-width: 767px) {
        font-size: 30px;
    }
`;
