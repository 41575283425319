import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { orderResultSetData } from '../actions';
import * as types from '../constants/actionTypes';

function* getOrderData(action) {
    try {
        const response = yield call(Api.getOrder, action.payload);
        yield put(orderResultSetData(response.data));
    } catch (e) {
        // ignore
    }
}

function* watchGetOrder() {
    yield takeLatest(types.ORDER_RESULT_GET_DATA, getOrderData);
}

export default function* watchOrderResult() {
    yield all([fork(watchGetOrder)]);
}
