const ENV_TYPES = {
    dev: {
        api: 'https://dev.api.miloo.co/api/',
        url: 'https://dev.web.miloo.co',
    },
    local: {
        api: 'https://dev.api.miloo.co/api/',
        url: 'localhost:3000',
    },
    stage: {
        api: 'https://stage.api.miloo.co/api/',
        url: 'https://stage.web.miloo.co',
    },
    production: {
        api: 'https://api.miloo.co/api/',
        url: 'https://miloo.co',
    },
};

export const getEnvironment = (env) => ENV_TYPES[env] || ENV_TYPES.local;
