import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    success: false,
    testDrivePoints: [],
};

const contactUsGetTestDriveInfo = (state) => ({ ...state, done: false });

const contactUsSetTestDriveInfo = (state, action) => ({
    ...state,
    done: true,
    testDrivePoints: action.payload,
});

export const testDriveReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONTACT_US_GET_TEST_DRIVE_INFO:
            return contactUsGetTestDriveInfo(state);
        case types.CONTACT_US_SET_TEST_DRIVE_INFO:
            return contactUsSetTestDriveInfo(state, action);
        default:
            return state;
    }
};
