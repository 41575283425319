import * as types from '../constants/actionTypes';

export const contactUsClear = () => ({ type: types.CONTACT_US_CLEAR });

export const contactUsGetUserInfo = () => ({
    type: types.CONTACT_US_GET_USER_INFO,
});

export const contactUsGetTestDriveInfo = () => ({
    type: types.CONTACT_US_GET_TEST_DRIVE_INFO,
});

export const contactUsGetTestDriveDescription = () => ({
    type: types.CONTACT_US_GET_TEST_DRIVE_DESCRIPTION,
});

export const contactUsGetTestDriveTimeSlots = (date) => ({
    type: types.CONTACT_US_GET_TEST_DRIVE_TIME_SLOTS,
    payload: date,
});

export const contactUsSetUserInfo = (userInfo) => ({
    type: types.CONTACT_US_SET_USER_INFO,
    payload: userInfo,
});

export const contactUsSetTestDriveInfo = (testDriveInfo) => ({
    type: types.CONTACT_US_SET_TEST_DRIVE_INFO,
    payload: testDriveInfo,
});

export const contactUsSetTestDriveDescription = (testDriveInfo) => ({
    type: types.CONTACT_US_SET_TEST_DRIVE_DESCRIPTION,
    payload: testDriveInfo,
});

export const contactUsSetTestDriveTimeSlots = (testDriveTimeSlots) => ({
    type: types.CONTACT_US_SET_TEST_DRIVE_TIME_SLOTS,
    payload: testDriveTimeSlots,
});

export const contactUsSendMessageStart = (data) => ({
    type: types.CONTACT_US_SEND_MESSAGE_START,
    payload: data,
});

export const contactUsSendMessageFinish = ({ newUserData, error = false }) => ({
    type: types.CONTACT_US_SEND_MESSAGE_FINISH,
    payload: newUserData,
    error,
});
