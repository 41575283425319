import styled from 'styled-components';

export const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 85px;
    width: 100%;
    @media (max-width: 1600px) {
        height: 75px;
    }
    @media (max-width: 767px) {
        height: ${(props) => (props.error ? '48px' : '75px')};
    }
`;

export const WrapperButton = styled.div`
    margin-top: ${(props) => (props.locale === 'de' ? '20px' : '0')};
`;

export const WrapperCheckbox = styled(InputWrapper)`
    margin-top: ${(props) => (props.locale === 'de' ? '20px' : '10px')};
    @media (max-width: 767px) {
        height: auto;
    }
`;
