import { all, call, debounce, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import {
    checkoutSetExperienceCenters,
    checkoutSetListWeek,
    checkoutSetTimeSlots,
    checkoutSetUserInfo,
} from '../actions';
import * as types from '../constants/actionTypes';

function* getUserInfo() {
    try {
        const response = yield call(Api.getUser);
        yield put(checkoutSetUserInfo(response.data));
    } catch (e) {
        // ignore
    }
}

function* getExperienceCenters() {
    try {
        const response = yield call(Api.getExperienceCenters);
        yield put(checkoutSetExperienceCenters(response.data));
    } catch (e) {
        // ignore
    }
}

function* getDayWeek(action) {
    try {
        const response = yield call(Api.getWeekDay, action.payload);
        yield put(checkoutSetListWeek(response.data));
    } catch (e) {
        yield put(checkoutSetListWeek({ weekdays: [], deliveryTypes: [] }));
    }
}

function* getTimeSlots(action) {
    try {
        const response = yield call(
            Api.getTimeSlotsCheckout,
            action.payload,
            action.date,
        );
        yield put(checkoutSetTimeSlots(response.data));
    } catch (e) {
        yield put(checkoutSetTimeSlots([]));
    }
}

function* watchGetUserInfo() {
    yield takeLatest(types.CHECKOUT_GET_USER_INFO, getUserInfo);
}

function* watchGetExperienceCenters() {
    yield takeLatest(
        types.CHECKOUT_GET_EXPERIENCE_CENTERS,
        getExperienceCenters,
    );
}

function* watchGetDayWeek() {
    yield debounce(400, types.CHECKOUT_GET_LIST_WEEK, getDayWeek);
}

function* watchGetTimeSlots() {
    yield takeLatest(types.CHECKOUT_GET_LIST_TIME_SLOTS, getTimeSlots);
}

export default function* watchCheckout() {
    yield all([
        fork(watchGetUserInfo),
        fork(watchGetExperienceCenters),
        fork(watchGetDayWeek),
        fork(watchGetTimeSlots),
    ]);
}
