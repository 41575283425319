import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

ReactGA.initialize('G-520TR3M87L');

const withGoogleAnalytics = (WrappedComponent) => (props) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.set({ page: location.pathname + location.search });
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return <WrappedComponent {...props} />;
};

export default withGoogleAnalytics;
