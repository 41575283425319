import * as types from '../constants/actionTypes';

const initialState = {
    listFaq: [],
};

const setFaqs = (state, action) => ({
    ...state,
    listFaq: action.payload,
});

const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_FAQ_ITEM:
            return setFaqs(state, action);
        default:
            return state;
    }
};

export default faqReducer;
