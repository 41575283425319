import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { vehiclesSet } from '../actions';
import * as types from '../constants/actionTypes';

function* getVehicles() {
    try {
        const response = yield call(Api.getVehicles);

        yield put(vehiclesSet(response.data));
    } catch (e) {
        yield put(vehiclesSet([]));
    }
}

function* watchGetVehicles() {
    yield takeLatest(types.VEHICLES_GET, getVehicles);
}

export default function* watchVehicles() {
    yield all([fork(watchGetVehicles)]);
}
