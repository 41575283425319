import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    error: '',
    success: false,
    email: '',
};

const resetPasswordStart = (state, action) => ({
    ...state,
    done: false,
    ...action.payload,
});

const resetPasswordSuccess = (state, action) => ({
    ...state,
    ...action.payload,
});

const resetPasswordError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
    success: false,
});

export const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_PASSWORD_START:
            return resetPasswordStart(state, action);
        case types.RESET_PASSWORD_SUCCESS:
            return resetPasswordSuccess(state, action);
        case types.RESET_PASSWORD_ERROR:
            return resetPasswordError(state, action);
        default:
            return state;
    }
};
