import * as types from '../constants/actionTypes';

const initialState = {
    data: {},
    created: false,
    error: null,
    loading: false,
};

const getResult = (state) => ({ ...state, loading: true, created: false });

const getResetState = (state) => ({ ...state, created: false });

const setQuotationResult = (state, { payload }) => ({
    ...state,
    loading: false,
    created: true,
    data: payload,
});

const setQuotationErrors = (state, { payload }) => ({
    ...state,
    loading: false,
    created: false,
    error: payload,
});

const orderResultReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_QUOTATION_DATA:
            return getResult(state);
        case types.SET_QUOTATION_DATA:
            return setQuotationResult(state, action);
        case types.SET_RESET_DONE:
            return getResetState(state, action);
        case types.SET_QUOTATION_ERRORS:
            return setQuotationErrors(state, action);
        default:
            return state;
    }
};

export default orderResultReducer;
