import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    success: false,
    testDriveDescription: {},
};

const contactUsGetTestDriveDescription = (state) => ({ ...state, done: false });

const contactUsSetTestDriveDescription = (state, action) => ({
    ...state,
    done: true,
    testDriveDescription: action.payload,
});

export const testDriveDescriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONTACT_US_GET_TEST_DRIVE_DESCRIPTION:
            return contactUsGetTestDriveDescription(state);
        case types.CONTACT_US_SET_TEST_DRIVE_DESCRIPTION:
            return contactUsSetTestDriveDescription(state, action);
        default:
            return state;
    }
};
