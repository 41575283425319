export const liveChatIds = {
    production: {
        en: '6',
        fr: '8',
        de: '7',
    },
    dev: {
        en: '2',
        fr: '4',
        de: '5',
    },
};
