import { combineReducers } from 'redux';

import cart from '../common/hoc/withHeaderAndFooter/redux/reducers';
import refurbishedProduct from '../features/RefurbishedProductPage/redux/reducers';
import blog from '../features/blog/redux/reducers';
import checkout from '../features/checkout/redux/reducers';
import configurate from '../features/configurate/redux/reducers';
import {
    contactUsReducer as contactUs,
    testDriveReducer as testDrive,
    testDriveDescriptionReducer as testDriveDescription,
    testDriveTimeSlotsReducer as testDriveTimeSlots,
} from '../features/contactUs/redux/reducers';
import emailConfirm from '../features/emailConfirm/redux/reducers';
import faq from '../features/faq/redux/reducers';
import homePage from '../features/landing/redux/reducers';
import {
    dictionaryReducer as dictionary,
    loginReducer as login,
    sessionReducer as session,
} from '../features/login/redux/reducers';
import orderDetails from '../features/orderDetails/redux/reducers';
import orderResult from '../features/orderResult/redux/reducers';
import orders from '../features/orders/redux/reducers';
import product from '../features/product/redux/reducers';
import profile from '../features/profile/redux/reducers';
import quotationResult from '../features/quotation/redux/reducers';
import refurbished from '../features/refurbished/redux/reducers';
import register from '../features/register/redux/reducers';
import {
    resetPasswordReducer as resetPassword,
    sendNewPasswordReducer,
    verifyEmailReducer,
} from '../features/resetPassword/redux/reducers';
import vehicle from '../features/vehicle/redux/reducers';
import vehicles from '../features/vehicles/redux/reducers';

const rootReducer = combineReducers({
    register,
    emailConfirm,
    login,
    resetPassword,
    verifyEmailReducer,
    sendNewPasswordReducer,
    session,
    dictionary,
    profile,
    cart,
    checkout,
    orderResult,
    quotationResult,
    contactUs,
    testDrive,
    testDriveDescription,
    testDriveTimeSlots,
    product,
    orders,
    orderDetails,
    vehicles,
    vehicle,
    faq,
    homePage,
    blog,
    refurbished,
    refurbishedProduct,
    configurate,
});

export default rootReducer;
