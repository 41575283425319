import { call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import i18n from '../../../../i18n';
import { registerError, registerSuccess } from '../actions';
import * as types from '../constants/actionTypes';

function* register(action) {
    try {
        yield call(Api.registerUser, action.payload);
        yield put(registerSuccess(action.payload.email));
    } catch (e) {
        yield put(
            registerError(
                e?.response?.data?.error?.message ||
                    i18n.t('invalid_data_text'),
            ),
        );
    }
}

export default function* watchRegister() {
    yield takeLatest(types.REGISTER_START, register);
}
