import axios from 'axios';

import Api from '../../api/apiCore';
import { baseURL } from '../../api/apiCore';
import { loginSuccess, logout } from '../../features/login/redux/actions';

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

export const resetTokenAndReattemptRequest = async (error, store) => {
    try {
        const { response: errorResponse } = error;
        const { session } = store.getState();
        const resetToken = session.refreshToken;

        if (!resetToken) {
            store.dispatch(logout());
            window.location.href = `${window.location.origin}/login`;
            return Promise.reject(error);
        }

        const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((accessToken) => {
                errorResponse.config.headers.Authorization =
                    'Bearer ' + accessToken;
                resolve(Api(errorResponse.config));
            });
        });
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            const response = await axios({
                method: 'POST',
                url: `${baseURL}auth/refresh/token`,
                headers: {
                    authorizaton: `Bearer ${resetToken}`,
                },
            });
            if (!response.data) {
                store.dispatch(logout());
                window.location.href = `${window.location.origin}/login`;
                return Promise.reject(error);
            }
            const tokens = response.data;
            store.dispatch(loginSuccess(tokens));
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(tokens.accessToken);
        }
        return retryOriginalRequest;
    } catch (err) {
        store.dispatch(logout());
        window.location.href = `${window.location.origin}/login`;
        return Promise.reject(err);
    }
};

const onAccessTokenFetched = (accessToken) => {
    subscribers.forEach((callback) => callback(accessToken));
    subscribers = [];
};

const addSubscriber = (callback) => subscribers.push(callback);
