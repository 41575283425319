import { all, fork } from 'redux-saga/effects';

import watchCart from '../common/hoc/withHeaderAndFooter/redux/sagas';
import watchRefurbishedProductDetails from '../features/RefurbishedProductPage/redux/sagas';
import watchPosts from '../features/blog/redux/sagas';
import watchCheckout from '../features/checkout/redux/sagas';
import watchSpecialOffers from '../features/configurate/redux/saga';
import watchContactUs from '../features/contactUs/redux/sagas';
import watchEmailConfirm from '../features/emailConfirm/redux/sagas';
import watchFaq from '../features/faq/redux/sagas';
import watchHomePage from '../features/landing/redux/sagas';
import watchLogin from '../features/login/redux/sagas';
import watchOrderDetails from '../features/orderDetails/redux/sagas';
import watchOrderResult from '../features/orderResult/redux/sagas';
import watchOrders from '../features/orders/redux/sagas';
import watchProduct from '../features/product/redux/sagas';
import watchProfile from '../features/profile/redux/sagas';
import watchQuotationResult from '../features/quotation/redux/sagas';
import watchRefurbished from '../features/refurbished/redux/sagas';
import watchRegister from '../features/register/redux/sagas';
import {
    watchResetPassword,
    watchSendNewPassword,
    watchVerifyEmail,
} from '../features/resetPassword/redux/sagas';
import watchVehicle from '../features/vehicle/redux/sagas';
import watchVehicles from '../features/vehicles/redux/sagas';

export default function* rootSaga() {
    yield all([
        fork(watchRegister),
        fork(watchEmailConfirm),
        fork(watchLogin),
        fork(watchProfile),
        fork(watchCart),
        fork(watchCheckout),
        fork(watchOrderResult),
        fork(watchQuotationResult),
        fork(watchContactUs),
        fork(watchProduct),
        fork(watchOrders),
        fork(watchOrderDetails),
        fork(watchVehicles),
        fork(watchVehicle),
        fork(watchResetPassword),
        fork(watchVerifyEmail),
        fork(watchSendNewPassword),
        fork(watchFaq),
        fork(watchHomePage),
        fork(watchPosts),
        fork(watchRefurbished),
        fork(watchRefurbishedProductDetails),
        fork(watchSpecialOffers),
    ]);
}
