const stylesTheme = {
    colors: {
        error100: '#dc2d2d',
        white100: '#ffffff',
        secondary010: '#e6e6ed',
        secondary100: '#28282d',
        secondary025: '#868593',
        secondary050: '#565763',
        secondary075: '#34343A',
        gray050: '#eeeef3',
        gray060: '#39393e',
        gray100: '#dddde8',
        gray200: '#868593',
        black100: '#000000',
        // TODO: switch all colors above to the ones below & delete above
        white_base: '#FFFFFF',
        black_base: '#1F232B',
        red_base: '#EF473F',
        grey_base: '#757575',
        grey_lighten_10: '#B7B7B7',
        grey_lighten_20: '#DEDEDE',
        grey_lighten_30: '#f5f5f5',
    },
    commonSizes: {
        navigationHeight: `80px`,
        navBannerHeight: '40px',
    },
    // TODO: add fonts and swith in the project
    mixins: {
        sectionSidePadding: `
            padding-inline: 4%;
        `,
        hideScrollBar: `
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        `,
    },
};

export const muiStylesConstants = {
    inputFields: {
        wrapper: {
            width: '49%',
        },
        wrapperShort: {
            width: '30%',
        },
        wrapperQuotation: {
            width: '28%',
            marginRight: '15px',
        },
        wrapperLong: {
            width: '68%',
        },
    },
};

export default stylesTheme;
