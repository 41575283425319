import * as types from '../constants/actionTypes';

export const getGoodsAndCategoryRefurbished = (data) => ({
    type: types.GET_REQUEST_ALL_REFURBISHED,
    payload: data,
});
export const getGoodsRefurbished = (data) => ({
    type: types.GET_GOODS_REFURBISHED,
    payload: data,
});
export const setGoodsCategotyRefurbished = (data) => ({
    type: types.SET_GOODS_CATEGORY_REFURBISHED,
    payload: data,
});
export const setGoodsRefurbished = (data) => ({
    type: types.SET_GOODS_REFURBISHED,
    payload: data,
});
