import {
    CART_DELETE_ITEM,
    CART_GET,
    CART_UPDATE_ITEM,
} from '../common/hoc/withHeaderAndFooter/redux/constants/actionTypes';
import {
    NOTIFICATION,
    PRODUCT_ADD_TO_CART,
    PRODUCT_ADD_TO_CART_OFFLINE,
} from '../features/product/redux/constants/actionTypes';

const cartMiddleware = (store) => (next) => (action) => {
    const isAuthenticated = store.getState().session.isAuthenticated;
    const watchedTypes = [
        CART_GET,
        CART_UPDATE_ITEM,
        CART_DELETE_ITEM,
        PRODUCT_ADD_TO_CART,
    ];
    const watchedNotificationType = [
        PRODUCT_ADD_TO_CART,
        PRODUCT_ADD_TO_CART_OFFLINE,
    ];

    const getProduct = (state, { payload }) => {
        const { refurbished, product } = state;
        const getFindItem = ({ id }) => id === payload?.id ?? {};

        return {
            ...refurbished.goods.find(getFindItem),
            ...product.products.find(getFindItem),
        };
    };

    const getCurrentProduct = () => getProduct(store.getState(), action);

    const setNotificationInfo = (type) => {
        const product = getCurrentProduct();
        const {
            name,
            configuratorCatalogId,
            cardPreviewInfo,
            currency,
            isRefurbished,
            price,
            originalPrice,
            media,
        } = product;
        const payload = action.payload
            ? {
                  name,
                  configuratorCatalogId,
                  cardPreviewInfo,
                  price,
                  originalPrice,
                  isRefurbished,
                  currency,
                  media,
              }
            : null;

        return { ...action, type, payload };
    };

    if (watchedNotificationType.includes(action.type)) {
        next(setNotificationInfo(NOTIFICATION));
    }

    if (watchedTypes.includes(action.type) && !isAuthenticated) {
        const type = action.type + '_OFFLINE';
        const currentCart = store.getState().cart.offlineCart;
        const product = getCurrentProduct();
        const payload = action.payload
            ? { ...action.payload, currentCart, product }
            : null;

        return next({ ...action, type, payload });
    }

    return next(action);
};

export default cartMiddleware;
