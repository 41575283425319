import * as types from '../constants/actionTypes';
import {
    experienceCenter,
    experienceCenters,
    formData,
} from '../constants/defaultState';

const initialState = {
    contacts: {
        done: false,
        withShipping: true,
        experienceCenters,
        experienceCenter,
        formData,
    },
    listWeekDay: {
        weekdays: [],
        deliveryTypes: [],
    },
    listTimeSlots: [],
};

const getMapping = (item, name, value, payload) => {
    if (item.id === payload.id) {
        return { ...item, [name]: value };
    }
    return { ...item, [name]: '' };
};

const checkoutSelectDeliveryMethod = (state, action) => ({
    ...state,
    contacts: { ...state.contacts, withShipping: action.payload },
});

const checkoutSetUserInfo = (state, action) => ({
    ...state,
    contacts: {
        ...state.contacts,
        formData: {
            ...state.contacts.formData,
            address: action.payload.address,
            country: action.payload.country,
            city: action.payload.city,
            phoneNumber: action.payload.phoneNumber,
            countryCode: action.payload.countryCode,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            zipCode: action.payload.zipCode,
            registerUser: action.payload.registerUser,
            deliveryTypeId: action.payload.deliveryTypeId,
            odooInstanceId: action.payload.odooInstanceId,
        },
    },
});

const checkoutSetListWeek = (state, action) => {
    return {
        ...state,
        listWeekDay: action.payload,
    };
};

const checkoutSetExperienceCenters = (state, action) => ({
    ...state,
    contacts: {
        ...state.contacts,
        experienceCenters: action.payload,
    },
});

const checkoutSetContactsInfo = (state, action) => ({
    ...state,
    contacts: {
        ...state.contacts,
        done: true,
        formData: {
            ...state.contacts.formData,
            ...action.payload,
        },
    },
});

const checkoutSelectPickUpCenter = (state, { payload }) => {
    const everyTrueFields = payload?.pickUpDate && payload?.timeSlot;
    const isActive = state.contacts.experienceCenter?.isActive;
    const center = state.contacts.experienceCenter;

    return {
        ...state,
        contacts: {
            ...state.contacts,
            experienceCenter: !isActive
                ? payload
                : !everyTrueFields
                ? center
                : payload,
        },
    };
};

const checkoutSelectPickUpDate = (state, { payload }) => ({
    ...state,
    contacts: {
        ...state.contacts,
        experienceCenter: {
            ...state.contacts.experienceCenter,
            pickUpDate: payload.date,
            timeSlot: '',
        },
        experienceCenters: state.contacts.experienceCenters.map((i) =>
            getMapping(i, 'pickUpDate', payload.date, payload),
        ),
    },
});

const checkoutSelectPickUpTime = (state, { payload }) => ({
    ...state,
    contacts: {
        ...state.contacts,
        experienceCenter: {
            ...state.contacts.experienceCenter,
            timeSlot: payload.time,
        },
        experienceCenters: state.contacts.experienceCenters.map((i) =>
            getMapping(i, 'timeSlot', payload.time, payload),
        ),
    },
});

const checkoutSetTimeSlots = (state, action) => ({
    ...state,
    listTimeSlots: action.payload,
});

const checkoutClean = () => ({ ...initialState });

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHECKOUT_SELECT_DELIVERY_METHOD:
            return checkoutSelectDeliveryMethod(state, action);
        case types.CHECKOUT_SET_USER_INFO:
            return checkoutSetUserInfo(state, action);
        case types.CHECKOUT_SET_CONTACTS_INFO:
            return checkoutSetContactsInfo(state, action);
        case types.CHECKOUT_SET_EXPERIENCE_CENTERS:
            return checkoutSetExperienceCenters(state, action);
        case types.CHECKOUT_SELECT_PICK_UP_CENTER:
            return checkoutSelectPickUpCenter(state, action);
        case types.CHECKOUT_SELECT_PICK_UP_DATE:
            return checkoutSelectPickUpDate(state, action);
        case types.CHECKOUT_SELECT_PICK_UP_TIME:
            return checkoutSelectPickUpTime(state, action);
        case types.CHECKOUT_SET_LIST_WEEK:
            return checkoutSetListWeek(state, action);
        case types.CHECKOUT_SET_LIST_TIME_SLOTS:
            return checkoutSetTimeSlots(state, action);
        case types.CHECKOUT_CLEAR_DATA:
            return checkoutClean();
        default:
            return state;
    }
};

export default checkoutReducer;
