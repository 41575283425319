import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { setQuotationErrors, setQuotationResult } from '../actions';
import * as types from '../constants/actionTypes';

const getIsAuthenticated = (state) => state['session'].isAuthenticated;

function* getQuotation(action) {
    try {
        const isAuthenticated = yield select(getIsAuthenticated);
        const callAPI = !isAuthenticated
            ? Api.getOrederQuotationIncognito
            : Api.getOrederQuotation;
        const { data } = yield call(callAPI, action.payload);
        yield put(setQuotationResult(data));
    } catch (e) {
        yield put(setQuotationErrors(e.response.data));
    }
}

function* watchGetQuotation() {
    yield takeLatest(types.GET_QUOTATION_DATA, getQuotation);
}

export default function* watchQuotationResult() {
    yield all([fork(watchGetQuotation)]);
}
