import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    success: false,
    error: '',
};

const registerStart = (state) => ({ ...state, done: false });

const registerSuccess = (state) => ({ ...state, done: true, success: true });

const registerError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
});

const registerClear = (initialState) => ({ ...initialState });

const registerClearError = (state) => ({ ...state, error: '' });

const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REGISTER_START:
            return registerStart(state);
        case types.REGISTER_SUCCESS:
            return registerSuccess(state);
        case types.REGISTER_ERROR:
            return registerError(state, action);
        case types.REGISTER_CLEAR:
            return registerClear(initialState);
        case types.REGISTER_CLEAR_ERROR:
            return registerClearError(state);
        default:
            return state;
    }
};

export default registerReducer;
