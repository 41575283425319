import {
    all,
    call,
    debounce,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import * as Api from '../../../../../api';
import {
    cartSet,
    cartUpdateItemError,
    checkoutSetDiscountProduct,
    errorCoupon,
} from '../actions';
import * as types from '../constants/actionTypes';

function* getCart() {
    try {
        const offlineCart = yield select((state) => state.cart.offlineCart);
        if (offlineCart?.items) {
            yield all(
                offlineCart.items.map(
                    ({ cartItemId, quantity, attachTo, configuratorOptions }) =>
                        call(Api.addCartItem, {
                            productId: cartItemId,
                            quantity,
                            attachTo,
                            configuratorOptions,
                        }),
                ),
            );
        }
        const response = yield call(Api.getCart);
        yield put(cartSet(response.data));
    } catch (e) {
        yield put(cartSet(null));
    }
}

function* updateCart(action) {
    try {
        const { id, quantity } = action.payload;
        yield call(Api.updateCartItem, id, { quantity });
        yield* getCart();
    } catch (e) {
        yield put(cartUpdateItemError(action.payload.id));
    }
}

function* deleteCartItem(action) {
    try {
        yield call(Api.deleteCartItem, action.payload.id);
        yield* getCart();
    } catch (e) {
        //ignore
    }
}

function* applyCoupon(action) {
    try {
        const response = yield call(
            Api.getDiscountProduct,
            action.payload,
            action.idProduct,
        );
        yield put(checkoutSetDiscountProduct(response.data));
        yield put(errorCoupon(null, null));
    } catch (e) {
        yield put(errorCoupon(action.idProduct, 'error'));
    }
}

function* watchGetDiscountProduct() {
    yield debounce(500, types.CHECKOUT_GET_DISCOUNT_PRODUCT, applyCoupon);
}

function* watchGetCart() {
    yield takeLatest(types.CART_GET, getCart);
}

function* watchUpdateCart() {
    yield debounce(750, types.CART_UPDATE_ITEM, updateCart);
}

function* watchDeleteCart() {
    yield takeLatest(types.CART_DELETE_ITEM, deleteCartItem);
}

export default function* watchCart() {
    yield all([
        fork(watchGetCart),
        fork(watchUpdateCart),
        fork(watchDeleteCart),
        fork(watchGetDiscountProduct),
    ]);
}
