import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { orderDetailsSet } from '../actions';
import * as types from '../constants/actionTypes';

function* getOrderDetails(action) {
    try {
        const orderId = action.payload;
        const response = yield call(Api.getOrder, orderId);
        yield put(orderDetailsSet(response.data));
    } catch (e) {
        yield put(orderDetailsSet(null));
    }
}

function* watchGetOrderDetails() {
    yield takeLatest(types.ORDER_DETAILS_GET, getOrderDetails);
}

export default function* watchOrderDetails() {
    yield all([fork(watchGetOrderDetails)]);
}
