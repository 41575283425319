import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    error: '',
};

const loginStart = (state) => ({ ...state, done: false });

const loginSuccess = (state) => ({ ...state, done: true });

const loginError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
});

const loginClearError = (state) => ({ ...state, error: '' });

const loginClear = (initialState) => ({ ...initialState });

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_START:
            return loginStart(state);
        case types.LOGIN_SUCCESS:
            return loginSuccess(state);
        case types.LOGIN_ERROR:
            return loginError(state, action);
        case types.LOGIN_CLEAR:
            return loginClear(initialState);
        case types.LOGIN_CLEAR_ERROR:
            return loginClearError(state);
        default:
            return state;
    }
};
