import * as types from '../constants/actionTypes';

const initialState = {
    goodsCategory: [],
    goods: [],
    pages: {
        offset: 0,
        limit: 0,
        total: 0,
    },
    id: null,
    loading: false,
};

const getRequest = (state) => ({ ...state, loading: true });

const setGoodsCategory = (state, { payload }) => ({
    ...state,
    goodsCategory: payload,
    loading: false,
});

const setGoodsRefurbished = (state, { payload }) => {
    const { data, id, ...rest } = payload;

    return {
        ...state,
        goods: data,
        pages: {
            ...rest,
        },
        id,
        loading: false,
    };
};

const RefurbishedReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REQUEST_ALL_REFURBISHED:
        case types.GET_GOODS_REFURBISHED:
            return getRequest(state);
        case types.SET_GOODS_CATEGORY_REFURBISHED:
            return setGoodsCategory(state, action);
        case types.SET_GOODS_REFURBISHED:
            return setGoodsRefurbished(state, action);
        default:
            return state;
    }
};

export default RefurbishedReducer;
