import compose from 'ramda/src/compose';

export const addProductToOfflineCart = (
    currentCart,
    product,
    attachTo,
    configuratorOptions,
    isQuantity,
    type,
) => {
    if (!currentCart) return initCart(product, type, configuratorOptions);
    const existingItem = currentCart.items.find(
        (item) => item.cartItemId === product.id && product.type !== 'service',
    );
    const isConfiguratorOptions = !existingItem?.configuratorOptions
        ? 1
        : isQuantity
        ? 1
        : 0;

    if (!existingItem) {
        return addNewItemToCurrentCart(
            currentCart,
            product,
            attachTo,
            configuratorOptions,
            type,
        );
    }

    if (existingItem) {
        return updateOfflineCartProducts(
            currentCart,
            product.id,
            existingItem.quantity + isConfiguratorOptions,
            attachTo,
        );
    }
};

export const updateOfflineCartProducts = (
    currentCart,
    cartToUpdateId,
    cartToUpdateQuantity,
    configuratorOptions,
) =>
    compose(
        calcCartItems,
        calcTotalPrice,
        updateOfflineCartProductsQuantity,
    )({
        currentCart,
        cartToUpdateId,
        cartToUpdateQuantity,
        configuratorOptions,
    });

export const deleteItemFromOfflineCart = (
    currentCart,
    cartToDeleteId,
    attachToId,
) =>
    compose(
        clearCartIfEmpty,
        calcCartItems,
        calcTotalPrice,
        deleteItem,
    )({ currentCart, cartToDeleteId, attachToId });

const deleteItem = ({ currentCart, cartToDeleteId, attachToId }) => {
    const withDeleted = currentCart.items
        .filter((item) => item.cartItemId === cartToDeleteId)
        .slice(0, -1);
    const updatedServiceAtach = currentCart.items.map((item) => {
        if (item.cartItemId === attachToId) {
            return {
                ...item,
                attachedServiceQuantity: item.attachedServiceQuantity - 1,
            };
        }
        return item;
    });

    return {
        ...currentCart,
        items: [
            ...updatedServiceAtach.filter(
                (item) => item.cartItemId !== cartToDeleteId,
            ),
            ...withDeleted,
        ],
    };
};

const addNewItemToCurrentCart = (
    currentCart,
    product,
    attachTo,
    configuratorOptions,
    type,
) =>
    compose(
        calcCartItems,
        calcTotalPrice,
        attachToProduct,
    )({
        attachTo,
        ...currentCart,
        items: [
            ...currentCart.items,
            {
                attachTo,
                cartItemId: product.id,
                couponName: product.couponName,
                couponDiscount: product.couponDiscount,
                quantity: 1,
                configuratorOptions,
                vehicle: product,
                type,
            },
        ],
    });

const attachToProduct = ({ attachTo, ...currentCart }) => {
    const attachedService = currentCart.items.map((item) => {
        if (
            attachTo &&
            attachTo?.vehicleId === item.cartItemId &&
            item.vehicle.type !== 'service'
        ) {
            return {
                ...item,
                attachedServiceQuantity: item.attachedServiceQuantity + 1 || 1,
            };
        }
        return item;
    });

    return {
        ...currentCart,
        items: [...attachedService],
    };
};

const initCart = (product, type, configuratorOptions) => ({
    count: 1,
    items: [
        {
            cartItemId: product.id,
            quantity: 1,
            type,
            configuratorOptions,
            vehicle: product,
        },
    ],
    totalPrice: {
        amount: product.price,
        currency: product.currency,
    },
});

const updateOfflineCartProductsQuantity = ({
    currentCart,
    cartToUpdateId,
    cartToUpdateQuantity,
}) => {
    return {
        ...currentCart,
        items: currentCart.items.map((item) =>
            item.cartItemId === cartToUpdateId
                ? { ...item, quantity: cartToUpdateQuantity }
                : item,
        ),
    };
};

const calcCartItems = (cart) => ({ ...cart, count: cart.items.length });

const calcTotalPrice = (cart) => {
    return {
        ...cart,
        totalPrice: {
            ...cart.totalPrice,
            amount: cart.items.reduce(
                (acc, cur) =>
                    acc +
                    cur.vehicle.originalPrice * (cur.quantity - 1) +
                    cur.vehicle.price,
                0,
            ),
        },
    };
};

const clearCartIfEmpty = (cart) => (cart.count ? cart : null);
