import { PRODUCT_ADD_TO_CART_OFFLINE } from '../../../../../features/product/redux/constants/actionTypes';
import {
    addProductToOfflineCart,
    deleteItemFromOfflineCart,
    updateOfflineCartProducts,
} from '../../shared/utils';
import * as types from '../constants/actionTypes';

const initialState = {
    offlineCart: null,
    done: true,
    data: null,
    loading: false,
    deleting: false,
    errorCoupon: {},
};

const cartClear = (initialState) => ({ ...initialState, offlineCart: null });

const cartGet = (state) => ({
    ...state,
    loading: true,
    deleting: false,
    done: false,
});

const cartDeleting = (state) => ({ ...state, deleting: true });

const cartSet = (state, action) => {
    return {
        ...state,
        done: true,
        data: action.payload,
        offlineCart: null,
        loading: false,
    };
};

const cartUpdateItem = (state) => ({ ...state, done: false });

const cartUpdateItemError = (state, action) => {
    return {
        ...state,
        done: true,
        data: {
            ...state.data,
            items: state.data.items.map((item) => {
                if (item.vehicle.id === action.payload) {
                    return { ...item };
                }
                return item;
            }),
        },
    };
};

const errorCoupon = (state, action) => ({
    ...state,
    errorCoupon: {
        text: action.payload.text,
        id: action.payload.id,
    },
});

const cartAddOffline = (state, action) => {
    const {
        currentCart,
        product,
        attachTo,
        configuratorOptions,
        isQuantity,
        type,
    } = action.payload;

    return {
        ...state,
        data: null,
        offlineCart: addProductToOfflineCart(
            currentCart,
            product,
            attachTo,
            configuratorOptions,
            isQuantity,
            type,
        ),
    };
};

const cartUpdateItemOffline = (state, action) => {
    const { currentCart, id, quantity } = action.payload;

    return {
        ...state,
        data: null,
        offlineCart: updateOfflineCartProducts(currentCart, id, quantity),
    };
};

const cartDeleteItemOffline = (state, action) => {
    const { currentCart, id, attachToId } = action.payload;

    return {
        ...state,
        data: null,
        offlineCart: deleteItemFromOfflineCart(currentCart, id, attachToId),
    };
};

const checkoutSetDiscountProduct = (state, action) => {
    if (state.offlineCart) {
        return {
            ...state,
            offlineCart: {
                ...state.offlineCart,
                items: state.offlineCart.items.map((item) =>
                    item.cartItemId === action.payload.id
                        ? {
                              ...item,
                              vehicle: action.payload,
                          }
                        : item,
                ),
            },
        };
    } else {
        return {
            ...state,
            data: {
                ...state.data,
                items: state.data.items.map((item) =>
                    item.vehicle.id === action.payload.id
                        ? {
                              ...item,
                              vehicle: action.payload,
                          }
                        : item,
                ),
            },
        };
    }
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CART_CLEAR:
            return cartClear(initialState, state);
        case types.CART_GET:
            return cartGet(state);
        case types.CART_DELETING:
            return cartDeleting(state);
        case types.CART_SET:
            return cartSet(state, action);
        case PRODUCT_ADD_TO_CART_OFFLINE:
            return cartAddOffline(state, action);
        case types.CART_UPDATE_ITEM_OFFLINE:
            return cartUpdateItemOffline(state, action);
        case types.CART_DELETE_ITEM_OFFLINE:
            return cartDeleteItemOffline(state, action);
        case types.CART_UPDATE_ITEM:
            return cartUpdateItem(state);
        case types.CART_UPDATE_ITEM_ERROR:
            return cartUpdateItemError(state, action);
        case types.CHECKOUT_SET_DISCOUNT_PRODUCT:
            return checkoutSetDiscountProduct(state, action);
        case types.ERROR_COUPON:
            return errorCoupon(state, action);
        default:
            return state;
    }
};

export default cartReducer;
