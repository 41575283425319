import i18n from '../../i18n';

export const GENDERS = [
    {
        value: 'male',
        label: i18n.t('gender_male'),
    },
    {
        value: 'female',
        label: i18n.t('gender_female'),
    },
    {
        value: 'other',
        label: i18n.t('gender_other'),
    },
];

export const PRODUCT_TYPES = [
    {
        value: 'mighty_beast',
        label: 'Mighty Beast',
    },
    {
        value: 'classy_beast',
        label: 'Classy Beast',
    },
    {
        value: 'cruisy_beast',
        label: 'Cruisy Beast',
    },
    {
        value: 'all',
        label: 'ALL',
    },
];

export const MAX_PHOTO_SIZE_KB = 16000;

export const linkVideo = 'https://www.youtube.com/watch?v=9UPPWbZMNio';

export const partners = [
    {
        city: 'city_geneva',
        info: [
            {
                nameCenter: 'geneva_center',
                address: 'address_geneva',
                phone: '022 320 91 26',
                url: 'https://tec-way.ch/',
                products: 'products_geneva_one',
            },
            {
                nameCenter: 'Genève Roule',
                address: 'Place de Montbrillant 17 \n1201 Genève',
                phone: '022 740 14 15',
                url: 'https://www.geneveroule.ch/en/rent-loan',
                products: 'products_geneva_two',
            },
        ],
    },
    {
        city: 'city_fribourg',
        info: [
            {
                nameCenter: 'fribourg_center',
                address: 'address_fribourg',
                phone: '026 663 36 06',
                url: 'https://www.velo-zone.ch/',
                products: 'products_fribourg',
            },
        ],
    },
    {
        city: 'city_basel',
        info: [
            {
                nameCenter: 'Breitenstein Bikes GmbH',
                address: 'Viehmarktgasse 39 \n4202 Laufen',
                phone: '0767017517',
                url: 'https://www.breitensteinbikes.ch',
                products:
                    'What is sold : Mighty und Classy Beast Händler (45km/h und 25km/h Elektrofahrräder und Scooter)',
            },
        ],
    },
    {
        city: 'Zurich/Zug/Luzern',
        info: [
            {
                nameCenter: 'Nicola Greco',
                address: 'CH-8932 Mettmenstetten',
                phone: '078 600 16 42',
                url: 'miloo@grecoo.ch',
                products: 'products_fribourg',
            },
        ],
    },
];
