import * as types from '../constants/actionTypes';
import { includeProducts } from '../constants/default';

const initialState = {
    products: [],
    nextPage: 0,
    totalItems: 0,
    subcategories: [],
    subProducts: [],
    subcategoryId: null,
    product: null,
    configuratorCatalogId: null,
    configuratorCreated: false,
    notification: null,
    reviews: [],
    loading: false,
    expiviError: null,
    updateConfigurator: false,
};

const getFilter = (product) => includeProducts.includes(product?.type);

const getProduct = (state) => ({ ...state, loading: true });

const setUpdateConfigurate = (state, { payload }) => ({
    ...state,
    updateConfigurator: payload,
});

const getCreatedConfigurator = (state) => ({
    ...state,
    configuratorCreated: true,
});

const productSetProducts = (
    state,
    { payload: { products, subcategories, id } },
) => {
    const hasProducts = products?.length ? products.filter(getFilter) : [];

    return {
        ...state,
        products,
        subProducts: hasProducts,
        subcategoryId: null,
        subcategories: subcategories?.data,
        configuratorCatalogId: id,
        loading: false,
        configuratorCreated: false,
    };
};

const productSelect = (state, action) => {
    const product = state.products.find(
        (product) => product.id === action.payload,
    );
    const id = product?.configuratorCatalogId || state.configuratorCatalogId;

    return {
        ...state,
        product: Boolean(product)
            ? { ...product, configuratorCatalogId: id }
            : null,
    };
};

const productNotificationAdd = (state, action) => ({
    ...state,
    notification: { ...action.payload, isShow: true },
});

const productNotificationRemove = (state) => ({
    ...state,
    notification: { ...state.notification, isShow: false },
});

const productSetReviews = (state, action) => ({
    ...state,
    reviews: action.payload,
});

const productsFilteredBySubcategories = (
    state,
    { payload: { productsSubcategories, id, offset, total } },
) => ({
    ...state,
    subProducts: productsSubcategories,
    nextPage: offset,
    totalItems: total,
    subcategoryId: id,
});

const expiviFailedProduct = (state, { payload }) => ({
    ...state,
    expiviError: payload,
});

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PRODUCT_GET_PRODUCTS:
            return getProduct(state, action);
        case types.PRODUCT_SET_PRODUCTS:
            return productSetProducts(state, action);
        case types.SET_PRODUCT_SUBCATEGORIES:
            return productsFilteredBySubcategories(state, action);
        case types.PRODUCT_SELECT:
            return productSelect(state, action);
        case types.NOTIFICATION:
            return productNotificationAdd(state, action);
        case types.NOTIFICATION_RESET:
            return productNotificationRemove(state, action);
        case types.SET_REVIEWS_PRODUCTS:
            return productSetReviews(state, action);
        case types.SET_EXPIVI_ERROR:
            return expiviFailedProduct(state, action);
        case types.SET_CONFIGURATION_CREATE:
            return getCreatedConfigurator(state);
        case types.SET_CONFIGURATION_UPDATE:
            return setUpdateConfigurate(state, action);
        default:
            return state;
    }
};

export default productReducer;
