import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getIsAuthenticated } from '../../../features/login/redux/selectors';

const NotAuthenticatedRoute = ({ component: Component, ...routeParams }) => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    return (
        <Route
            {...routeParams}
            render={(props) =>
                isAuthenticated ? (
                    <Redirect to="/account" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default NotAuthenticatedRoute;
