import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { cartGet } from '../../../../common/hoc/withHeaderAndFooter/redux/actions';
import {
    productSetProducts,
    reviewsSetProduct,
    setConfigurateCreate,
    setProductErrors,
    setProductSubcategories,
} from '../actions';
import * as types from '../constants/actionTypes';

const productTypes = ['mighty_beast', 'classy_beast', 'cruisy_beast'];

const getState = (state) => state.product;
const filtred = (val, idx, arr) =>
    arr.map((item) => item['id']).indexOf(val['id']) === idx;

const differenceBy = (item, newData) => {
    const map = {};

    for (const value of item.attributes) {
        map[value.attribute_value_name] = value.attribute_value_name;
    }

    return newData.attributes.some(
        (attr) => map[attr.attribute_value_name] !== attr.attribute_value_name,
    );
};

const hasEditConfigurator = (oldData, newData, bundle) => {
    if (bundle) {
        const item = oldData.find(({ bundle_uuid }) => bundle_uuid === bundle);

        if (differenceBy(item, newData[0])) {
            newData[0].bundle_uuid = bundle;
            newData[0].quantity = item.quantity;
            return oldData
                .filter(({ bundle_uuid }) => bundle_uuid !== bundle)
                .concat(newData);
        } else {
            return oldData;
        }
    }

    return oldData.concat(newData);
};

function* getProducts(action) {
    try {
        const isType = productTypes.find((e) => e === action.payload);

        const [product, productTypesRequest, subcategory] = yield all([
            call(Api.getProducts),
            isType ? call(Api.getProductTypes, action.payload) : () => {},
            call(Api.getSubcategories),
        ]);

        const {
            data: { items: products },
        } = product;
        const { data } = subcategory;

        yield put(
            productSetProducts({
                products,
                subcategories: data,
                product: productTypesRequest?.data,
                id: productTypesRequest?.data?.configuratorCatalogId,
                vehicleType: action.payload,
            }),
        );
    } catch (e) {
        yield put(productSetProducts([]));
    }
}

function* getProductSubcategories({ payload }) {
    try {
        const { data } = yield call(Api.getProductSubcategories, payload);
        const { products } = yield select(getState);
        const filtredData = !payload?.isNextPage
            ? data.data
            : products.concat(data.data).filter(filtred);

        yield put(
            setProductSubcategories({
                productsSubcategories: filtredData,
                offset: data.offset,
                total: data.total,
                id: payload.subcategoryId,
            }),
        );
    } catch (e) {
        yield put(setProductErrors(e?.response?.data?.error?.message));
    }
}

function* addProductToCart(action) {
    try {
        yield call(Api.addCartItem, {
            productId: action.payload.id,
            quantity: action.payload.quantity,
            attachTo: action.payload.attachTo,
            configuratorOptions: action.payload.configuratorOptions,
        });
        yield put(cartGet());
    } catch (e) {}
}

function* getReviewsProduct(action) {
    try {
        const response = yield call(Api.getReviews, action.payload);
        yield put(reviewsSetProduct(response.data));
    } catch (e) {}
}

function* getConfiguratePrices({
    payload: {
        isEmpty,
        newQuantity,
        configurations,
        products,
        expivi,
        bundle,
        bundleId,
        productId,
    },
}) {
    try {
        const { data } = yield call(Api.configuratorPrices, { configurations });
        const newProducts = products.map((item) => ({
            ...item,
            productId,
            attributes: data.configurations[0].attributes,
        }));

        if (newQuantity) {
            const { configured_products, ...rest } = JSON.parse(
                localStorage.getItem('configurate'),
            );
            const update = configured_products.map(({ ...rest }) => {
                if (bundleId === rest.bundle_uuid) {
                    return { ...rest, quantity: rest.quantity + 1 };
                }

                return rest;
            });

            localStorage.setItem(
                'configurate',
                JSON.stringify({
                    ...rest,
                    configured_products: update,
                }),
            );
        }

        if (isEmpty) {
            const newData = { ...expivi, configured_products: newProducts };
            localStorage.setItem('configurate', JSON.stringify(newData));
        } else if (!newQuantity) {
            const mergeProdcut = hasEditConfigurator(
                expivi.configured_products,
                newProducts,
                bundle,
            );

            localStorage.setItem(
                'configurate',
                JSON.stringify({
                    ...expivi,
                    configured_products: mergeProdcut,
                }),
            );
        }
        yield put(setConfigurateCreate());
    } catch (e) {}
}

function* watchAddProductToCart() {
    yield takeLatest(types.PRODUCT_ADD_TO_CART, addProductToCart);
}

function* watchGetProducts() {
    yield takeLatest(types.PRODUCT_GET_PRODUCTS, getProducts);
}

function* watchGetReviews() {
    yield takeLatest(types.GET_REVIEWS_PRODUCTS, getReviewsProduct);
}

function* watchProductSubcategories() {
    yield takeLatest(types.GET_PRODUCT_SUBCATEGORIES, getProductSubcategories);
}

function* watchConfiguratePrices() {
    yield takeLatest(types.GET_CONFIGURATION_PRICES, getConfiguratePrices);
}

export default function* watchProduct() {
    yield all([
        fork(watchGetProducts),
        fork(watchAddProductToCart),
        fork(watchGetReviews),
        fork(watchProductSubcategories),
        fork(watchConfiguratePrices),
    ]);
}
