import * as types from '../constants/actionTypes';

export const vehicleGet = (id) => ({ type: types.VEHICLE_GET, payload: id });

export const vehicleSet = (vehicle) => ({
    type: types.VEHICLE_SET,
    payload: vehicle,
});

export const vehicleClear = () => ({ type: types.VEHICLE_CLEAR });
