import PropTypes from 'prop-types';
import styled from 'styled-components';

import darkLogo from '../../assets/images/dark.svg';
import lightLogo from '../../assets/images/light.svg';

export const MiloLogo = styled.img.attrs((props) => ({
    src: props.color === 'light' ? lightLogo : darkLogo,
    alt: 'Miloo',
}))`
    width: 100px;
    height: 35px;
`;

MiloLogo.propTypes = {
    color: PropTypes.oneOf(['light', 'dark']),
};
