import * as types from '../constants/actionTypes';

export const emailConfirmSendStart = (email) => ({
    type: types.EMAIL_CONFIRM_SEND_START,
    payload: { email },
});

export const emailConfirmSendError = (error) => ({
    type: types.EMAIL_CONFIRM_SEND_ERROR,
    error,
});

export const emailConfirmSendSuccess = () => ({
    type: types.EMAIL_CONFIRM_SEND_SUCCESS,
});

export const emailConfirmSendClear = () => ({
    type: types.EMAIL_CONFIRM_CLEAR,
});

export const emailConfirmEnableResend = () => ({
    type: types.EMAIL_CONFIRM_ENABLE_RESEND,
});
