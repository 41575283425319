import i18next from 'i18next';

import { resetTokenAndReattemptRequest } from '../common/utils';
import Api from './apiCore';

Api.defaults.headers.common['Accept-Language'] = i18next.language;

export const setupInterceptors = (store) => {
    Api.interceptors.request.use(
        (config) => {
            config.params = { ...config.params, lang: i18next.language };
            config.headers['Accept-Language'] = i18next.language;
            const { session } = store.getState();
            const token = session.accessToken;

            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error),
    );

    Api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (
                error.response.status === 401 &&
                store.getState().session.isAuthenticated
            ) {
                return resetTokenAndReattemptRequest(error, store);
            }
            return Promise.reject(error);
        },
    );
};
