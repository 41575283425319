const REDUCER_NAME = 'login';
const DICTIONARY_REDUCER_NAME = 'dictionary';
const SESSION_REDUCER_NAME = 'session';

export const getLoginDone = (state) => state[REDUCER_NAME].done;

export const getLoginError = (state) => state[REDUCER_NAME].error;

export const getCountries = (state) =>
    state[DICTIONARY_REDUCER_NAME].countries.map((country) => ({
        label: country.name,
        value: country.name,
    }));

export const getCountryCodes = (state) =>
    state[DICTIONARY_REDUCER_NAME].countries.map((country) => ({
        label: country.dial_code,
        value: country.dial_code,
    }));

export const getIsAuthenticated = (state) =>
    state[SESSION_REDUCER_NAME].isAuthenticated;
