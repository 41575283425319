import * as types from '../constants/actionTypes';

export const cartClear = () => ({ type: types.CART_CLEAR });

export const cartGet = () => ({ type: types.CART_GET });

export const cartDeleting = () => ({ type: types.CART_DELETING });

export const cartSet = (data) => ({
    type: types.CART_SET,
    payload: data,
});

export const cartUpdateItem = (id, quantity) => ({
    type: types.CART_UPDATE_ITEM,
    payload: { id, quantity },
});

export const cartUpdateItemError = (updateItemId) => ({
    type: types.CART_UPDATE_ITEM_ERROR,
    payload: updateItemId,
});

export const errorCoupon = (id, text) => ({
    type: types.ERROR_COUPON,
    payload: { id, text },
});

export const cartDeleteItem = (id, attachToId) => ({
    type: types.CART_DELETE_ITEM,
    payload: { id, attachToId },
});

export const checkoutSetDiscountProduct = (product) => ({
    type: types.CHECKOUT_SET_DISCOUNT_PRODUCT,
    payload: product,
});

export const checkoutGetDiscountProduct = (coupon, idProduct) => ({
    type: types.CHECKOUT_GET_DISCOUNT_PRODUCT,
    payload: coupon,
    idProduct,
});
