export const experienceCenters = [
    {
        name: 'Miloo Main Place',
        address: '10 Place du Grand Mezel, 1204, Geneva',
        phoneNumber: '+41791586291',
        pickUpDate: '',
        email: 'center@center.center',
        workHours: 'Mon - Sat 9:00 - 18:00',
        timeSlot: '',
    },
];

export const experienceCenter = {
    name: null,
    address: null,
    phoneNumber: null,
    pickUpDate: null,
    workHours: null,
    timeSlot: null,
};

export const formData = {
    address: null,
    country: null,
    city: null,
    phoneNumber: null,
    countryCode: null,
    firstName: null,
    lastName: null,
    email: null,
    zipCode: null,
    registerUser: true,
    deliveryTypeId: null,
    odooInstanceId: '1',
};
