import * as types from '../constants/actionTypes';

export const registerStart = (userData) => ({
    type: types.REGISTER_START,
    payload: userData,
});

export const registerSuccess = (email) => ({
    type: types.REGISTER_SUCCESS,
    payload: email,
});

export const registerError = (error) => ({ type: types.REGISTER_ERROR, error });

export const registerClear = () => ({ type: types.REGISTER_CLEAR });

export const registerClearError = () => ({ type: types.REGISTER_CLEAR_ERROR });
