import styled from 'styled-components';

import { mobile } from '../constants/resolutions';

export const LayoutWrapper = styled.section`
    padding: ${(props) => (props.noPadding ? '3rem 0rem 3rem 0rem' : '3rem')};

    @media (max-width: ${mobile}px) {
        padding: ${(props) =>
            props.noPadding ? '1.5rem 0rem 1.5rem 0rem' : '1.5rem'};
    }
`;
