export const CART_CLEAR = 'CART_CLEAR';
export const CART_GET = 'CART_GET';
export const CART_SET = 'CART_SET';
export const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM';
export const CART_UPDATE_ITEM_OFFLINE = 'CART_UPDATE_ITEM_OFFLINE';
export const CART_UPDATE_ITEM_SUCCESS = 'CART_UPDATE_ITEM_SUCCESS';
export const CART_UPDATE_ITEM_ERROR = 'CART_UPDATE_ITEM_ERROR';
export const CART_DELETE_ITEM = 'CART_DELETE_ITEM';
export const CART_DELETING = 'CART_DELETING';
export const CART_DELETE_ITEM_OFFLINE = 'CART_DELETE_ITEM_OFFLINE';

export const CHECKOUT_GET_DISCOUNT_PRODUCT = 'CHECKOUT_GET_DISCOUNT_PRODUCT';
export const CHECKOUT_SET_DISCOUNT_PRODUCT = 'CHECKOUT_SET_DISCOUNT_PRODUCT';
export const ERROR_COUPON = 'ERROR_COUPON';
