import { call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { defaultData } from '../../shared/components/Experience/constants/default';
import { setHomeData, setHomeError } from '../actions';
import * as types from '../constants/actionTypes';

function* getHomePage() {
    try {
        const { data } = yield call(Api.getHomePage);
        const { advantageSections } = data.translations;
        const isDefault = !advantageSections.length
            ? defaultData
            : advantageSections;

        yield put(
            setHomeData({
                ...data,
                translations: { advantageSections: isDefault },
            }),
        );
    } catch (e) {
        console.log(e?.response?.data?.error?.message, 'err');
        yield put(
            setHomeError(
                e?.response?.data?.error?.message || 'Get home-page error',
            ),
        );
    }
}

export default function* watchProfile() {
    yield takeLatest(types.GET_HOME_DATA, getHomePage);
}
