export const CONTACT_US_CLEAR = 'CONTACT_US_CLEAR';
export const CONTACT_US_GET_USER_INFO = 'CONTACT_US_GET_USER_INFO';
export const CONTACT_US_SET_USER_INFO = 'CONTACT_US_SET_USER_INFO';
export const CONTACT_US_SEND_MESSAGE_START = 'CONTACT_US_SEND_MESSAGE_START';
export const CONTACT_US_SEND_MESSAGE_FINISH = 'CONTACT_US_SEND_MESSAGE_FINISH';

export const CONTACT_US_GET_TEST_DRIVE_INFO = 'CONTACT_US_GET_TEST_DRIVE_INFO';
export const CONTACT_US_SET_TEST_DRIVE_INFO = 'CONTACT_US_SET_TEST_DRIVE_INFO';

export const CONTACT_US_GET_TEST_DRIVE_DESCRIPTION =
    'CONTACT_US_GET_TEST_DRIVE_DESCRIPTION';
export const CONTACT_US_SET_TEST_DRIVE_DESCRIPTION =
    'CONTACT_US_SET_TEST_DRIVE_DESCRIPTION';

export const CONTACT_US_GET_TEST_DRIVE_TIME_SLOTS =
    'CONTACT_US_GET_TEST_DRIVE_TIME_SLOTS';
export const CONTACT_US_SET_TEST_DRIVE_TIME_SLOTS =
    'CONTACT_US_SET_TEST_DRIVE_TIME_SLOTS';
