import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { de, en, fr } from './assets/translations';

const supportedLangs = ['en', 'de', 'fr'];

const detectLanguage = (supportLangs) => {
    const locale = localStorage.getItem('language');
    const browserLang = navigator.language.slice(0, 2);
    let browserSetting;

    if (!locale && supportLangs.includes(browserLang)) {
        browserSetting = browserLang;
        localStorage.setItem('language', browserSetting);
    }

    return locale || browserSetting || 'en';
};

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
    de: {
        translation: de,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: detectLanguage(supportedLangs),
    keySeparator: false,
});

export default i18n;
