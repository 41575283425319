import connect from '../../../../../../assets/images/home-page/connect.png';
import eBikeTwo from '../../../../../../assets/images/home-page/e-bike2.png';
import eBike from '../../../../../../assets/images/home-page/e-bike.png';

export const defaultData = [
    {
        background: {
            url: eBike,
        },
        id: 1,
        title: 'extra_attitude_title',
        description: 'extra_attitude_description',
        bullets:
            '- extra_autonomy - extra_safety - extra_capacity - extra_comfort',
    },
    {
        background: {
            url: eBikeTwo,
        },
        id: 2,
        title: 'extra_attitude_title',
        description: 'extra_attitude_description',
        bullets: '- start_to_ride - challenge_routine - reveal_inner_beast',
    },
    {
        background: {
            url: connect,
        },
        id: 3,
        title: 'extra_attitude_title',
        description: 'extra_attitude_description',
        bullets:
            '- digital_cockpit - extra_info - extra_navigation, - extra_security',
    },
];
