import { isAuthenticated } from '../../shared/utils';
import * as types from '../constants/actionTypes';

const initialState = {
    accessToken: '',
    refreshToken: '',
    resetPasswordToken: '',
    isAuthenticated: false,
};

const loginSuccess = (state, action) => {
    return isAuthenticated(action.payload.accessToken)
        ? {
              ...state,
              accessToken: action.payload.accessToken,
              refreshToken: action.payload.refreshToken,
              isAuthenticated: true,
          }
        : state;
};

const logout = (initialState) => ({ ...initialState });

export const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            return loginSuccess(state, action);
        case types.LOGOUT:
            return logout(initialState);
        case 'VERIFY_EMAIL_SUCCESS':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
