import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import {
    profileGetUserError,
    profileGetUserSuccess,
    profileSaveImageError,
    profileSaveImageSuccess,
    profileUpdateUserError,
    profileUpdateUserSuccess,
} from '../actions';
import * as types from '../constants/actionTypes';

function* getUser() {
    try {
        const response = yield call(Api.getUser);
        yield put(profileGetUserSuccess(response.data));
    } catch (e) {
        yield put(
            profileGetUserError(
                e?.response?.data?.error?.message || 'Get user error',
            ),
        );
    }
}

function* saveProfileImage(action) {
    try {
        const response = yield call(Api.updateUserImage, action.payload);
        yield put(profileSaveImageSuccess(response.data));
    } catch (e) {
        yield put(
            profileSaveImageError(
                e?.response?.data?.error?.message || 'Save image error',
            ),
        );
    }
}

function* updateUser(action) {
    try {
        const response = yield call(Api.updateUser, action.payload.data);
        yield put(
            profileUpdateUserSuccess({
                data: response.data,
                formType: action.payload.formType,
            }),
        );
    } catch (e) {
        yield put(
            profileUpdateUserError(
                e?.response?.data?.error?.message || 'Update user error',
            ),
        );
    }
}

function* watchGetUser() {
    yield takeLatest(types.PROFILE_GET_USER_START, getUser);
}

function* watchSaveProfileImage() {
    yield takeLatest(types.PROFILE_SAVE_PHOTO, saveProfileImage);
}

function* watchUpdateUser() {
    yield takeLatest(types.PROFILE_UPDATE_USER_START, updateUser);
}

export default function* watchProfile() {
    yield all([
        fork(watchGetUser),
        fork(watchSaveProfileImage),
        fork(watchUpdateUser),
    ]);
}
