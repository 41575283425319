import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { setSpecialOffers } from '../actions';
import * as types from '../constants/actionTypes';

function* getOffers() {
    try {
        let response = yield call(Api.specialOffers);

        if (!response.data) {
            response = yield call(Api.specialOffers, 'en');
        }
        yield put(setSpecialOffers(response.data));
    } catch (e) {
        //errors
    }
}

function* watchGetOffers() {
    yield takeLatest(types.GET_SPECIAL_OFFERS, getOffers);
}

export default function* watchOffers() {
    yield fork(watchGetOffers);
}
