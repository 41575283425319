// Posts
export const GET_POSTS = 'GET_POSTS';
export const SET_POSTS = 'SET_POSTS';
export const SET_POSTS_ERROR = 'SET_POSTS_ERROR';
export const CLEAR_POSTS = 'CLEAR_POSTS';

// Post
export const GET_POST = 'GET_POST';
export const SET_POST = 'SET_POST';

// Category
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
