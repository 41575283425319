import * as types from '../constants/actionTypes';

const initialState = {
    done: false,
    orderId: null,
    orderData: null,
};

const orderResultSetId = (state, action) => ({
    ...state,
    orderId: action.payload,
});

const orderResultClear = (initialState) => ({ ...initialState });

const orderResultSetData = (state, action) => ({
    ...state,
    done: true,
    orderData: action.payload,
});

const orderResultReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ORDER_RESULT_SET_ID:
            return orderResultSetId(state, action);
        case types.ORDER_RESULT_CLEAR:
            return orderResultClear(initialState);
        case types.ORDER_RESULT_SET_DATA:
            return orderResultSetData(state, action);
        default:
            return state;
    }
};

export default orderResultReducer;
