import * as types from '../constants/actionTypes';

export const orderDetailsGet = (orderId) => ({
    type: types.ORDER_DETAILS_GET,
    payload: orderId,
});

export const orderDetailsSet = (orderDetails) => ({
    type: types.ORDER_DETAILS_SET,
    payload: orderDetails,
});

export const orderDetailsClear = () => ({ type: types.ORDER_DETAILS_CLEAR });
