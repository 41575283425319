import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    data: null,
};

const vehicleGet = (state) => ({ ...state, done: false });

const vehicleSet = (state, action) => ({
    ...state,
    done: true,
    data: action.payload,
});

const vehicleClear = (initialState) => initialState;

const vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.VEHICLE_GET:
            return vehicleGet(state);
        case types.VEHICLE_SET:
            return vehicleSet(state, action);
        case types.VEHICLE_CLEAR:
            return vehicleClear(initialState);
        default:
            return state;
    }
};

export default vehicleReducer;
