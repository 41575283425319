import * as types from '../constants/actionTypes';

const initialState = {
    countries: [],
};

const dictionarySet = (state, action) => ({ ...state, ...action.payload });

export const dictionaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DICTIONARY_SET:
            return dictionarySet(state, action);
        default:
            return state;
    }
};
