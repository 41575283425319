export const ONLY_LETTERS_REGEX = /^[a-zA-Z]+$/;

export const ONLY_LETTERS_WITH_ONE_SPACE_REGEX = /^[a-zA-Z ]+$/;

export const ONLY_NUMBERS_REGEX = /^[\d]+$/;

export const NUMBERS_AND_SLASHES_REGEX = /^[\d/]+$/;

export const STARTS_WITH_SPACE_REGEX = /^\s/;

export const SUCCESSIVE_SPACES_REGEX = /\s{2,}/g;

export const SPACE_REGEX = /\s+/g;

export const DATE_REGEX =
    /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

export const EMAIL_REGEX =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const VIDEO_TYPE_REGEX = /video/;

export const GIF_TYPE_REGEX = /gif/;

export const PROHIBITED_PHOTO_TYPE_REGEX = /image\/(?!(jpg|jpeg|png))/i;
