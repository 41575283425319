import * as types from '../constants/actionTypes';

export const checkoutSelectDeliveryMethod = (deliveryMethod) => ({
    type: types.CHECKOUT_SELECT_DELIVERY_METHOD,
    payload: deliveryMethod,
});

export const checkoutGetUserInfo = () => ({
    type: types.CHECKOUT_GET_USER_INFO,
});

export const checkoutSetUserInfo = (userInfo) => ({
    type: types.CHECKOUT_SET_USER_INFO,
    payload: userInfo,
});

export const checkoutGetExperienceCenters = () => ({
    type: types.CHECKOUT_GET_EXPERIENCE_CENTERS,
});

export const checkoutSetExperienceCenters = (experienceCenters) => ({
    type: types.CHECKOUT_SET_EXPERIENCE_CENTERS,
    payload: experienceCenters,
});

export const checkoutSetContactsInfo = (contactsInfo) => ({
    type: types.CHECKOUT_SET_CONTACTS_INFO,
    payload: contactsInfo,
});

export const checkoutSelectPickUpCenter = (center) => ({
    type: types.CHECKOUT_SELECT_PICK_UP_CENTER,
    payload: center,
});

export const checkoutSelectPickUpDate = (date, id) => ({
    type: types.CHECKOUT_SELECT_PICK_UP_DATE,
    payload: { date, id },
});

export const checkoutSelectPickUpTime = (time, id) => ({
    type: types.CHECKOUT_SELECT_PICK_UP_TIME,
    payload: { time, id },
});

export const checkoutClean = () => ({ type: types.CHECKOUT_CLEAN });

export const checkoutSetListWeek = (list) => ({
    type: types.CHECKOUT_SET_LIST_WEEK,
    payload: list,
});

export const checkoutGetListWeek = (zipCode) => ({
    type: types.CHECKOUT_GET_LIST_WEEK,
    payload: zipCode,
});

export const checkoutSetTimeSlots = (list) => ({
    type: types.CHECKOUT_SET_LIST_TIME_SLOTS,
    payload: list,
});

export const checkoutClearData = () => ({ type: types.CHECKOUT_CLEAR_DATA });

export const checkoutGetTimeSlots = (zipcode, date) => ({
    type: types.CHECKOUT_GET_LIST_TIME_SLOTS,
    payload: zipcode,
    date,
});
