import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { clearPosts, setPost, setPosts, setPostsError } from '../actions';
import * as types from '../constants/actionTypes';

function* getCallsAPI({ payload, calls }) {
    const [articles, articleCategories] = yield all(
        calls.map((api) => {
            return call(api, payload);
        }),
    );

    return { articles, articleCategories };
}

const getState = (state) => state.blog;
const hasIsCall = (isEmpty, isSwitchLeng) =>
    isSwitchLeng || !isEmpty ? Api.getBlogCategories : () => {};

function* getPosts({ payload }) {
    try {
        const { categories } = yield select(getState);
        const { articles, articleCategories } = yield getCallsAPI({
            payload,
            calls: [
                Api.getBlogPosts,
                hasIsCall(categories.length, payload?.isSwitchLeng),
            ],
        });

        if (payload?.isSwitchLeng) {
            put(clearPosts());
        }

        yield put(
            setPosts({
                posts: articles.data,
                searching: payload?.search,
                categories:
                    payload?.isSwitchLeng || !categories.length
                        ? articleCategories.data.data
                        : categories,
            }),
        );
    } catch (e) {
        console.log(e?.response?.data?.error?.message, 'err');
        yield put(
            setPostsError(
                e?.response?.data?.error?.message || 'Get blog posts error',
            ),
        );
    }
}

function* getPost({ payload }) {
    try {
        const { categories } = yield select(getState);
        const { articles, articleCategories } = yield getCallsAPI({
            payload,
            calls: [Api.getBlogPost, hasIsCall(categories.length)],
        });

        yield put(
            setPost({
                post: articles.data,
                categories: Boolean(categories.length)
                    ? categories
                    : articleCategories.data.data,
            }),
        );
    } catch (e) {
        yield put(setPostsError(e?.response?.data));
    }
}

export default function* watchPosts() {
    yield takeLatest(types.GET_POSTS, getPosts);
    yield takeLatest(types.GET_POST, getPost);
}
