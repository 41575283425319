import * as types from '../constants/actionTypes';

export const ordersGet = (status) => ({
    type: types.ORDERS_GET,
    payload: status,
});

export const ordersSet = (orders) => ({
    type: types.ORDERS_SET,
    payload: orders,
});

export const ordersClear = () => ({ type: types.ORDERS_CLEAR });
