import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import i18n from '../../../../i18n';
import { checkoutSetUserInfo } from '../../../checkout/redux/actions';
import { loginError, loginSuccess } from '../actions';
import { setDictionary } from '../actions';
import * as types from '../constants/actionTypes';

function* login(action) {
    try {
        const response = yield call(Api.login, action.payload);
        yield put(loginSuccess(response.data));
    } catch (e) {
        yield put(
            loginError(
                e?.response?.data?.error?.message ||
                    i18n.t('invalid_credentials_text'),
            ),
        );
    }
}

function* getDictionary() {
    try {
        const response = yield call(Api.getUserDictionary);
        yield put(setDictionary(response.data));
    } catch (e) {
        yield put(
            setDictionary({
                country: [],
                countryCode: [],
            }),
        );
    }
}

function* handleLoginSuccess() {
    try {
        const response = yield call(Api.getUser);
        yield put(checkoutSetUserInfo(response.data));
    } catch (e) {
        // ignore
    }
}

function* watchUserLogin() {
    yield takeLatest(types.LOGIN_START, login);
}

function* watchGetDictionary() {
    yield takeLatest(types.DICTIONARY_GET, getDictionary);
}

function* watchUserLoginSuccess() {
    yield takeLatest(types.LOGIN_SUCCESS, handleLoginSuccess);
}

export default function* watchLogin() {
    yield all([
        fork(watchUserLogin),
        fork(watchGetDictionary),
        fork(watchUserLoginSuccess),
    ]);
}
