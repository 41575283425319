export const CHECKOUT_SELECT_DELIVERY_METHOD =
    'CHECKOUT_SELECT_DELIVERY_METHOD';
export const CHECKOUT_GET_USER_INFO = 'CHECKOUT_GET_USER_INFO';
export const CHECKOUT_SET_USER_INFO = 'CHECKOUT_SET_USER_INFO';
export const CHECKOUT_GET_EXPERIENCE_CENTERS =
    'CHECKOUT_GET_EXPERIENCE_CENTERS';
export const CHECKOUT_SET_EXPERIENCE_CENTERS =
    'CHECKOUT_SET_EXPERIENCE_CENTERS';
export const CHECKOUT_SET_CONTACTS_INFO = 'CHECKOUT_SET_CONTACTS_INFO';
export const CHECKOUT_SET_PAYMENT_DONE = 'CHECKOUT_SET_PAYMENT_DONE';
export const CHECKOUT_SELECT_PICK_UP_CENTER = 'CHECKOUT_SELECT_PICK_UP_CENTER';
export const CHECKOUT_SELECT_PICK_UP_DATE = 'CHECKOUT_SELECT_PICK_UP_DATE';
export const CHECKOUT_SELECT_PICK_UP_TIME = 'CHECKOUT_SELECT_PICK_UP_TIME';
export const CHECKOUT_CONFIRM_PAYMENT_START = 'CHECKOUT_CONFIRM_PAYMENT_START';
export const CHECKOUT_CONFIRM_PAYMENT_SUCCESS =
    'CHECKOUT_CONFIRM_PAYMENT_SUCCESS';
export const CHECKOUT_CONFIRM_PAYMENT_ERROR = 'CHECKOUT_CONFIRM_PAYMENT_ERROR';
export const CHECKOUT_CLEAN = 'CHECKOUT_CLEAN';
export const CHECKOUT_GET_LIST_WEEK = 'CHECKOUT_GET_LIST_WEEK';
export const CHECKOUT_SET_LIST_WEEK = 'CHECKOUT_SET_LIST_WEEK';

export const CHECKOUT_GET_LIST_TIME_SLOTS = 'CHECKOUT_GET_LIST_TIME_SLOTS';
export const CHECKOUT_SET_LIST_TIME_SLOTS = 'CHECKOUT_SET_LIST_TIME_SLOTS';
export const CHECKOUT_CLEAR_DATA = 'CHECKOUT_CLEAR_DATA';
