import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    success: false,
    testDriveTimeSlots: [],
};

const contactUsGetTestTimeSlots = (state) => ({ ...state, done: false });

const contactUsSetTestTimeSlots = (state, action) => ({
    ...state,
    done: true,
    testDriveTimeSlots: action.payload,
});

export const testDriveTimeSlotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONTACT_US_GET_TEST_DRIVE_INFO:
            return contactUsGetTestTimeSlots(state);
        case types.CONTACT_US_SET_TEST_DRIVE_INFO:
            return contactUsSetTestTimeSlots(state, action);
        default:
            return state;
    }
};
