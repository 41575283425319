import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import {
    contactUsSendMessageFinish,
    contactUsSetTestDriveDescription,
    contactUsSetTestDriveInfo,
    contactUsSetTestDriveTimeSlots,
    contactUsSetUserInfo,
} from '../actions';
import * as types from '../constants/actionTypes';

function* getUserInfo() {
    try {
        const response = yield call(Api.getUser);
        yield put(contactUsSetUserInfo(response.data));
    } catch (e) {
        yield put(contactUsSetUserInfo({ firstName: '', email: '' }));
    }
}

function* getTestDriveInfo() {
    try {
        const response = yield call(Api.getTestDrivePoints);
        yield put(contactUsSetTestDriveInfo(response.data));
    } catch (e) {
        yield put(contactUsSetUserInfo([]));
    }
}

function* getTestDriveDescription() {
    try {
        const response = yield call(Api.getTestDriveInfo);
        yield put(contactUsSetTestDriveDescription(response.data));
    } catch (e) {
        yield put(contactUsSetUserInfo([]));
    }
}

function* getTestDriveTimeSlots(action) {
    try {
        const response = yield call(
            Api.getTestDriveTimeSlotsRequest,
            action.payload,
        );
        yield put(contactUsSetTestDriveTimeSlots(response.data));
    } catch (e) {
        yield put(contactUsSetUserInfo([]));
    }
}

function* sendMessage(action) {
    try {
        yield call(Api.contactUs, action.payload);
        yield put(contactUsSendMessageFinish({ newUserData: action.payload }));
    } catch (e) {
        yield put(
            contactUsSendMessageFinish({
                newUserData: action.payload,
                error: true,
            }),
        );
    }
}

function* watchGetUserInfo() {
    yield takeLatest(types.CONTACT_US_GET_USER_INFO, getUserInfo);
}

function* watchGetTestDriveInfo() {
    yield takeLatest(types.CONTACT_US_GET_TEST_DRIVE_INFO, getTestDriveInfo);
}

function* watchGetTestDriveDescription() {
    yield takeLatest(
        types.CONTACT_US_GET_TEST_DRIVE_DESCRIPTION,
        getTestDriveDescription,
    );
}

function* watchGetTestDriveTimeSlots() {
    yield takeLatest(
        types.CONTACT_US_GET_TEST_DRIVE_TIME_SLOTS,
        getTestDriveTimeSlots,
    );
}

function* watchSendMessage() {
    yield takeLatest(types.CONTACT_US_SEND_MESSAGE_START, sendMessage);
}

export default function* watchContactUs() {
    yield all([
        fork(watchGetUserInfo),
        fork(watchSendMessage),
        fork(watchGetTestDriveInfo),
        fork(watchGetTestDriveDescription),
        fork(watchGetTestDriveTimeSlots),
    ]);
}
