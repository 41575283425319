import { REGISTER_SUCCESS } from '../../../register/redux/constants/actionTypes';
import * as types from '../constants/actionTypes';

const initialState = {
    email: '',
    done: true,
    success: false,
    error: '',
    requestEmailBlocked: false,
};

const emailConfirmSetEmail = (state, action) => ({
    ...state,
    email: action.payload,
});

const emailConfirmSendStart = (state) => ({
    ...state,
    done: false,
    success: false,
    error: '',
    requestEmailBlocked: true,
});

const emailConfirmSendError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
});

const emailConfirmSendSuccess = (state) => ({
    ...state,
    done: true,
    success: true,
});

const emailConfirmEnableResend = (state) => ({
    ...state,
    requestEmailBlocked: false,
});

const emailConfirmClear = (initialState) => ({ ...initialState });

const emailConfirmReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return emailConfirmSetEmail(state, action);
        case types.EMAIL_CONFIRM_SEND_START:
            return emailConfirmSendStart(state);
        case types.EMAIL_CONFIRM_SEND_ERROR:
            return emailConfirmSendError(state, action);
        case types.EMAIL_CONFIRM_SEND_SUCCESS:
            return emailConfirmSendSuccess(state);
        case types.EMAIL_CONFIRM_ENABLE_RESEND:
            return emailConfirmEnableResend(state);
        case types.EMAIL_CONFIRM_CLEAR:
            return emailConfirmClear(initialState);
        default:
            return state;
    }
};

export default emailConfirmReducer;
