import { isAfter, sub } from 'date-fns/esm';
import compose from 'ramda/src/compose';

import i18n from '../../i18n';
import {
    DATE_REGEX,
    EMAIL_REGEX,
    GIF_TYPE_REGEX,
    MAX_PHOTO_SIZE_KB,
    NUMBERS_AND_SLASHES_REGEX,
    ONLY_LETTERS_REGEX,
    ONLY_LETTERS_WITH_ONE_SPACE_REGEX,
    ONLY_NUMBERS_REGEX,
    PROHIBITED_PHOTO_TYPE_REGEX,
    SPACE_REGEX,
    STARTS_WITH_SPACE_REGEX,
    SUCCESSIVE_SPACES_REGEX,
    VIDEO_TYPE_REGEX,
} from '../constants';

export const swallowError = (fn) => (args) => args.error ? args : fn(args);

export const validateStartsWithSpace = (fieldName) => (args) =>
    args.value && STARTS_WITH_SPACE_REGEX.test(args.value)
        ? {
              ...args,
              error: `${fieldName} ${i18n.t('cannot_start_from_space')}`,
          }
        : args;

export const validateSuccessiveSpaces = (fieldName) => (args) =>
    args.value && SUCCESSIVE_SPACES_REGEX.test(args.value)
        ? {
              ...args,
              error: `${fieldName} ${i18n.t(
                  'cannot_contain_2_success_spaces',
              )}`,
          }
        : args;

export const validateSpace = (fieldName) => (args) =>
    args.value && SPACE_REGEX.test(args.value)
        ? {
              ...args,
              error: `${fieldName} ${i18n.t('cannot_contain_the_space')}`,
          }
        : args;

export const validateMinLength = (minLength, fieldName) => (args) =>
    args.value && args.value.length < minLength
        ? {
              ...args,
              error: `${i18n.t(
                  'min_length_symbols',
              )} ${fieldName} - ${minLength} ${i18n.t('symbols_text')}`,
          }
        : args;

export const validateStrictLength = (length, fieldName) => (args) =>
    args.value && args.value.length !== length
        ? {
              ...args,
              error: `${i18n.t(
                  'the_length_of_the',
              )} ${fieldName} - ${length} ${i18n.t('symbols_text')}`,
          }
        : args;

export const validateMaxLength = (maxLength, fieldName) => (args) =>
    args.value.length > maxLength
        ? {
              ...args,
              error: `${i18n.t(
                  'max_length_symbols',
              )} ${fieldName} - ${maxLength} ${i18n.t('symbols_text')}`,
          }
        : args;

export const validatePhoneNumberDigits = (maxLength, minLength) => (args) =>
    (ONLY_NUMBERS_REGEX.test(args.value) && args.value.length > maxLength) ||
    (ONLY_NUMBERS_REGEX.test(args.value) && args.value.length < minLength)
        ? { ...args, error: `${i18n.t('error_valid_phone_number_digits')}` }
        : args;

export const validateRequired = (fieldName) => (args) =>
    !args.value
        ? { ...args, error: `${fieldName} ${i18n.t('error_required_field')}` }
        : args;

export const validateEquality = (value) => (args) =>
    args.value !== value
        ? { ...args, error: i18n.t('pass_does_not_match') }
        : args;

export const validateDateFormat = (args) =>
    args.value && !DATE_REGEX.test(args.value)
        ? { ...args, error: i18n.t('date_of_birth_must_be_format') }
        : args;

export const validateMinAge = (minAge, isAfter, sub) => (args) => {
    const date = args.value.split('/');
    const newDate = date[1] + '/' + date[0] + '/' + date[2];

    return args.value &&
        isAfter(new Date(newDate), sub(new Date(), { years: minAge }))
        ? {
              ...args,
              error: `${i18n.t(
                  'valid_text_you_can_join_us',
              )} ${minAge} ${i18n.t('years_text')}`,
          }
        : args;
};

export const validateDateInFuture = (isAfter) => (args) => {
    const date = args.value.split('/');
    const newDate = date[1] + '/' + date[0] + '/' + date[2];
    const isInvalidDate =
        new Date(newDate).toString() === i18n.t('invalid_date_text');

    return args.value &&
        (isAfter(new Date(newDate), new Date()) || isInvalidDate)
        ? { ...args, error: i18n.t('date_of_birth_in_future') }
        : args;
};

export const validateCharacters = (regex, regexExplanation) => (args) => {
    return args.value && !regex.test(args.value)
        ? { ...args, error: regexExplanation }
        : args;
};

export const validateMaxFileSize =
    (maxSizeKb = 0, errorMsg) =>
    (args) =>
        args.value && args.value.size / 1000 > maxSizeKb
            ? { ...args, error: errorMsg }
            : args;

export const validateProhibitedFileType =
    (prohibitedFormat, errorMsg) => (args) =>
        args.value && prohibitedFormat.test(args.value.type)
            ? { ...args, error: errorMsg }
            : args;

export const validateEmailRegex = (args) =>
    !EMAIL_REGEX.test(args.value)
        ? { ...args, error: i18n.t('error_format_email') }
        : args;

export const validateMessage = (value = '') =>
    compose(
        swallowError(validateMinLength(10, i18n.t('message_field'))),
        validateRequired(i18n.t('message_field')),
    )({ value });

export const validateFirstName = (value = '') =>
    compose(
        swallowError(validateSuccessiveSpaces(i18n.t('first_name'))),
        swallowError(validateStartsWithSpace(i18n.t('first_name'))),
        swallowError(validateMaxLength(15, i18n.t('first_name'))),
        swallowError(validateMinLength(2, i18n.t('first_name'))),
        validateRequired(i18n.t('first_name')),
    )({ value });

export const validateLastName = (value = '') =>
    compose(
        swallowError(validateSuccessiveSpaces(i18n.t('last_name'))),
        swallowError(validateStartsWithSpace(i18n.t('last_name'))),
        swallowError(validateMaxLength(30, i18n.t('last_name'))),
        swallowError(validateMinLength(2, i18n.t('last_name'))),
        validateRequired(i18n.t('last_name')),
    )({ value });

export const validatePassword = (value = '') =>
    compose(
        swallowError(validateSpace(i18n.t('password_label'))),
        swallowError(validateMaxLength(20, i18n.t('password_label'))),
        swallowError(validateMinLength(10, i18n.t('password_label'))),
        validateRequired(i18n.t('password_label')),
    )({ value });

export const validateRepeatPassword = (value = '', password) =>
    compose(
        swallowError(validateSpace(i18n.t('password_label'))),
        swallowError(validateMaxLength(20, i18n.t('password_label'))),
        swallowError(validateMinLength(10, i18n.t('password_label'))),
        swallowError(validateEquality(password)),
        validateRequired(i18n.t('password_label')),
    )({ value });

export const validateDate = (value = '') =>
    compose(
        swallowError(validateMinAge(16, isAfter, sub)),
        swallowError(validateDateInFuture(isAfter)),
        swallowError(validateDateFormat),
        swallowError(
            validateCharacters(
                NUMBERS_AND_SLASHES_REGEX,
                i18n.t('you_can_not_use_characters'),
            ),
        ),
    )({ value });

export const validateCity = (value = '') =>
    compose(
        swallowError(
            validateCharacters(
                ONLY_LETTERS_WITH_ONE_SPACE_REGEX,
                i18n.t('field_only_characters_a_z'),
            ),
        ),
        swallowError(validateSuccessiveSpaces(i18n.t('city'))),
        swallowError(validateStartsWithSpace(i18n.t('city'))),
        swallowError(validateMaxLength(20, i18n.t('city'))),
        swallowError(validateMinLength(2, i18n.t('city'))),
        validateRequired(i18n.t('city')),
    )({ value });

export const validateNationality = (value = '') =>
    compose(
        swallowError(
            validateCharacters(
                ONLY_LETTERS_REGEX,
                i18n.t('field_only_characters_a_z'),
            ),
        ),
        swallowError(validateSuccessiveSpaces(i18n.t('nationality'))),
        swallowError(validateStartsWithSpace(i18n.t('nationality'))),
        swallowError(validateMaxLength(30, i18n.t('nationality'))),
        validateMinLength(1, i18n.t('nationality')),
    )({ value });

export const validatePhoneNumber = (value = '', isRequired = false) =>
    compose(
        swallowError(
            validateCharacters(
                ONLY_NUMBERS_REGEX,
                i18n.t('error_valid_phone_number'),
            ),
        ),
        swallowError(validateSuccessiveSpaces(i18n.t('phone_number'))),
        swallowError(validateStartsWithSpace(i18n.t('phone_number'))),
        swallowError(validatePhoneNumberDigits(13, 6, i18n.t('phone_number'))),
        isRequired && validateRequired(i18n.t('phone_number')),
    )({ value });

export const validateEmail = (value = '') =>
    compose(
        swallowError(validateEmailRegex),
        swallowError(validateMaxLength(128, i18n.t('email'))),
        validateRequired(i18n.t('email_label')),
    )({ value });

export const validateCountry = (value = '') =>
    compose(validateRequired(i18n.t('country')))({ value });

export const validateDateDelivery = (value = '') =>
    compose(validateRequired(i18n.t('field_date')))({ value });

export const validateTimeSlots = (value = '') =>
    compose(validateRequired(i18n.t('delivery_time_field_checkout')))({
        value,
    });

export const validateCountryCode = (value = '') =>
    compose(validateRequired(i18n.t('field_select_code_checkout')))({ value });

export const validateTimeSlot = (value = '') =>
    compose(validateRequired(i18n.t('field_time')))({ value });

export const validateZipCode = (value = '') =>
    compose(
        swallowError(validateSuccessiveSpaces(i18n.t('field_zip_code'))),
        swallowError(validateStartsWithSpace(i18n.t('field_zip_code'))),
        swallowError(validateMaxLength(7, i18n.t('field_zip_code'))),
        swallowError(validateMinLength(4, i18n.t('field_zip_code'))),
        validateRequired(i18n.t('field_zip_code')),
    )({ value });

export const validateAddress = (value = '') =>
    compose(
        swallowError(validateSuccessiveSpaces(i18n.t('address_checkout'))),
        swallowError(validateStartsWithSpace(i18n.t('address_checkout'))),
        swallowError(validateMaxLength(110, i18n.t('address_checkout'))),
        validateRequired(i18n.t('address_checkout')),
    )({ value });

export const validatePhoto = (value) =>
    compose(
        swallowError(
            validateProhibitedFileType(
                PROHIBITED_PHOTO_TYPE_REGEX,
                i18n.t('valid_format_img'),
            ),
        ),
        swallowError(
            validateProhibitedFileType(
                GIF_TYPE_REGEX,
                i18n.t('not_download_gif'),
            ),
        ),
        swallowError(
            validateProhibitedFileType(
                VIDEO_TYPE_REGEX,
                i18n.t('download_only_photo'),
            ),
        ),
        validateMaxFileSize(MAX_PHOTO_SIZE_KB, i18n.t('size_img_valid')),
    )({ value });

export const transformTextToDate = (text) =>
    text
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2');
// .replace(/[^\d\/]/g,'');

export const replaceEmptyValuesWithNull = (values) =>
    Object.entries(values).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value === '' ? null : value,
        }),
        {},
    );

export const replaceNullWithString = (values) =>
    Object.entries(values).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value === null ? '' : value,
        }),
        {},
    );

export const getValidationErrors = (fields) =>
    Object.entries(fields).reduce(
        (error, [key, value]) => (value ? { ...error, [key]: value } : error),
        {},
    );
