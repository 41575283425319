import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import styled from 'styled-components';

const icons = (status) => {
    switch (status) {
        case 'RECEIVED':
            return {
                border: '1px solid rgb(224 238 229)',
                bgColor: 'rgb(224 238 229)',
                textColor: '#258947',
                text: i18n.t('received_text'),
            };
        case 'DELIVERED':
            return {
                border: '1px solid rgb(224 238 229)',
                bgColor: 'rgb(224 238 229)',
                textColor: '#258947',
                text: i18n.t('delivered_text'),
            };
        case 'CANCELED':
            return {
                border: '1px solid rgb(250 226 226)',
                bgColor: 'rgb(250 226 226)',
                textColor: '#dc2d2d',
                text: i18n.t('canceled_text'),
            };
        case 'AWAITING_DISPATCH':
            return {
                border: '1px solid rgb(252 247 231)',
                bgColor: 'rgb(252 247 231)',
                textColor: '#d19604',
                text: i18n.t('awaiting_dispatch'),
            };
        case 'ORDER_BEING_PROCESS':
            return {
                border: '1px solid rgb(252 247 231)',
                bgColor: 'rgb(252 247 231)',
                textColor: '#d19604',
                text: i18n.t('order_being_progress'),
            };
        case 'READY_FOR_PICK_UP':
            return {
                border: '1px solid rgb(252 247 231)',
                bgColor: 'rgb(252 247 231)',
                textColor: '#d19604',
                text: i18n.t('ready_pick_up'),
            };
        case 'SHIPPED':
            return {
                border: '1px solid rgb(252 247 231)',
                bgColor: 'rgb(252 247 231)',
                textColor: '#d19604',
                text: i18n.t('shipped_text'),
            };
        case 'PAYMENT_FAILED':
            return {
                border: '1px solid #dc2d2d',
                bgColor: 'transparent',
                textColor: '#dc2d2d',
                text: i18n.t('payment_failed'),
            };
        case 'PAYMENT_SENT':
            return {
                border: '1px solid #258947',
                bgColor: 'transparent',
                textColor: '#258947',
                text: i18n.t('payment_send_text'),
            };
        default:
            return {
                border: '1px solid #404040',
                bgColor: 'transparent',
                textColor: '#404040',
                text: 'none',
            };
    }
};

const Inner = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    height: 24px;
    padding: 0 16px;
    border-radius: 12px;
    @media (max-width: 767px) {
        align-self: self-start;
    }
    > span {
        font-size: 12px !important;
        font-weight: 700 !important;
        letter-spacing: 1px;
        line-height: 1.33;
        color: inherit !important;
    }
`;

export const OrderStatus = ({ status }) => {
    return (
        <Inner
            style={{
                backgroundColor: icons(status).bgColor,
                color: icons(status).textColor,
                border: icons(status).border,
            }}
        >
            <span>{icons(status).text}</span>
        </Inner>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.string.isRequired,
};
