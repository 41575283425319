import * as types from '../constants/actionTypes';

// Posts
export const getPosts = (params) => ({
    type: types.GET_POSTS,
    payload: params,
});
export const setPosts = (data) => ({ type: types.SET_POSTS, payload: data });
export const setPostsError = (error) => ({
    type: types.SET_POSTS_ERROR,
    payload: error,
});
export const clearPosts = () => ({ type: types.CLEAR_POSTS });

// Post
export const getPost = (params) => ({ type: types.GET_POST, payload: params });
export const setPost = (data) => ({ type: types.SET_POST, payload: data });

// Category
export const setSelectedCategory = (data) => ({
    type: types.SET_SELECTED_CATEGORY,
    payload: data,
});
