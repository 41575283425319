import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...routeParams }) => {
    const session = useSelector((state) => state.session, shallowEqual);
    return (
        <Route
            {...routeParams}
            render={(props) => {
                if (!session.isAuthenticated) return <Redirect to="/login" />;
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
