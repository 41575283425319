import { call, delay, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import {
    emailConfirmEnableResend,
    emailConfirmSendError,
    emailConfirmSendSuccess,
} from '../actions';
import { RESEND_EMAIL_DELAY_MS } from '../constants';
import * as types from '../constants/actionTypes';

function* emailConfirm(action) {
    try {
        yield call(Api.resendEmail, action.payload);
        yield put(emailConfirmSendSuccess());
        yield delay(RESEND_EMAIL_DELAY_MS);
        yield put(emailConfirmEnableResend());
    } catch (e) {
        yield put(emailConfirmSendError(e));
        yield delay(RESEND_EMAIL_DELAY_MS);
        yield put(emailConfirmEnableResend());
    }
}

export default function* watchEmailConfirm() {
    yield takeLatest(types.EMAIL_CONFIRM_SEND_START, emailConfirm);
}
