import * as types from '../constants/actionTypes';

const initialState = {
    posts: [],
    post: {},
    categories: [],
    selectedCategoryId: undefined,
    pinned: {},
    limit: 20,
    loading: false,
    error: '',
    nextPage: 0,
    total: 0,
};

const getStart = (state, { payload }) => {
    return { ...state, loading: !payload?.isNextPage };
};

const getAllPosts = (state, { payload }) => {
    const {
        posts: { data, pinned, offset, total },
        categories,
        searching,
    } = payload;

    return {
        ...state,
        posts: searching ? data : [...state.posts, ...data],
        categories,
        pinned,
        total,
        nextPage: offset,
        loading: false,
    };
};

const getOnePost = (state, { payload: { post, categories } }) => ({
    ...state,
    post: post,
    categories,
    loading: false,
    error: '',
});

const setSelectedCategory = (state, { payload }) => ({
    ...state,
    selectedCategoryId: payload,
});

const getError = (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
});

const clearPosts = (state) => ({ ...state, posts: [] });

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_POST:
        case types.GET_POSTS:
            return getStart(state, action);
        case types.SET_POSTS:
            return getAllPosts(state, action);
        case types.SET_POST:
            return getOnePost(state, action);
        case types.SET_SELECTED_CATEGORY:
            return setSelectedCategory(state, action);
        case types.SET_POSTS_ERROR:
            return getError(state, action);
        case types.CLEAR_POSTS:
            return clearPosts(state);
        default:
            return state;
    }
};

export default blogReducer;
