import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollIntoView = ({ children }) => {
    const prevLocation = useRef();
    const location = useLocation();

    useEffect(() => {
        if (
            prevLocation.current !== location.pathname &&
            !location.hash.length
        ) {
            window.scrollTo(0, 0);
            prevLocation.current = location.pathname;
        }
    }, [location]);

    return children;
};

export default ScrollIntoView;
