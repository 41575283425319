export const PROFILE_GET_USER_START = 'PROFILE_GET_USER_START';
export const PROFILE_GET_USER_SUCCESS = 'PROFILE_GET_USER_SUCCESS';
export const PROFILE_GET_USER_ERROR = 'PROFILE_GET_USER_ERROR';
export const PROFILE_UPDATE_USER_START = 'PROFILE_UPDATE_USER_START';
export const PROFILE_UPDATE_USER_SUCCESS = 'PROFILE_UPDATE_USER_SUCCESS';
export const PROFILE_UPDATE_USER_ERROR = 'PROFILE_UPDATE_USER_ERROR';
export const PROFILE_SAVE_PHOTO = 'PROFILE_SAVE_PHOTO';
export const PROFILE_SAVE_PHOTO_SUCCESS = 'PROFILE_SAVE_PHOTO_SUCCESS';
export const PROFILE_SAVE_PHOTO_ERROR = 'PROFILE_SAVE_PHOTO_ERROR';
export const PROFILE_CLEAR = 'PROFILE_CLEAR';
export const PROFILE_RESET_FORM = 'PROFILE_RESET_FORM';
