import styled from 'styled-components';

import logo from '../../assets/images/dark.svg';

export const LogoBackground = styled.div`
    height: 100%;
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(${logo});
        background-size: cover;
        background-position: -1215px -15px;
        background-attachment: fixed;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        opacity: 0.03;
        z-index: -1;
        @media (min-width: 1920px) {
            background-position: -1700px -15px;
        }
    }
`;
