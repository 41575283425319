import * as types from '../constants/actionTypes';

const initialState = {
    isEdit: false,
    cartId: null,
    specialOffers: null,
};

const setEditConfiguration = (state, { payload }) => ({
    ...state,
    isEdit: payload,
});
const setCartId = (state, { payload }) => ({ ...state, cartId: payload });
const setPecialOffers = (state, { payload }) => ({
    ...state,
    specialOffers: payload,
});

const configuratorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_EDIT_CONFIGURATION:
            return setEditConfiguration(state, action);
        case types.SET_CART_ID_CONFIGURATION:
            return setCartId(state, action);
        case types.SET_SPECIAL_OFFERS:
            return setPecialOffers(state, action);
        default:
            return state;
    }
};

export default configuratorReducer;
