import styled from 'styled-components';

import logo from '../../assets/images/done.svg';

export const DoneLogo = styled.img.attrs(() => ({ src: logo, alt: 'done' }))`
    width: 35px;
    height: 35px;
`;

export default DoneLogo;
