import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { vehicleSet } from '../actions';
import * as types from '../constants/actionTypes';

function* getVehicle(action) {
    try {
        const response = yield call(Api.getVehicle, action.payload);
        yield put(vehicleSet(response.data));
    } catch (e) {
        yield put(vehicleSet([]));
    }
}

function* watchGetVehicle() {
    yield takeLatest(types.VEHICLE_GET, getVehicle);
}

export default function* watchVehicle() {
    yield all([fork(watchGetVehicle)]);
}
