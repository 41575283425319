import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    error: '',
    success: false,
};

const verifyEmailStart = (state, action) => ({
    ...state,
    done: false,
    ...action.payload,
});

const verifyEmailSuccess = (state, action) => ({ ...state, ...action.payload });

const verifyEmailError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
    success: false,
});

export const verifyEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.VERIFY_EMAIL_START:
            return verifyEmailStart(state, action);
        case types.VERIFY_EMAIL_SUCCESS:
            return verifyEmailSuccess(state, action);
        case types.VERIFY_EMAIL_ERROR:
            return verifyEmailError(state, action);
        default:
            return state;
    }
};
