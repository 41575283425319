import * as types from '../constants/actionTypes';

export const loginStart = (credentials) => ({
    type: types.LOGIN_START,
    payload: credentials,
});

export const loginSuccess = (tokens) => ({
    type: types.LOGIN_SUCCESS,
    payload: tokens,
});

export const loginError = (error) => ({ type: types.LOGIN_ERROR, error });

export const loginClearError = () => ({ type: types.LOGIN_CLEAR_ERROR });

export const loginClear = () => ({ type: types.LOGIN_CLEAR });

export const logout = () => ({ type: types.LOGOUT });
