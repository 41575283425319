import * as types from '../constants/actionTypes';

const initialState = {
    data: {
        advantageSections: [],
        video: null,
    },
    error: '',
};

const getStart = (state) => ({ ...state });

const getSuccess = (state, { payload: { video, translations } }) => {
    const { advantageSections } = translations;

    return {
        ...state,
        data: {
            video,
            advantageSections,
        },
    };
};

const getError = (state, action) => ({ ...state, error: action.error });

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_HOME_DATA:
            return getStart(state);
        case types.SET_HOME_DATA:
            return getSuccess(state, action);
        case types.SET_HOME_DATA_ERROR:
            return getError(state, action);
        default:
            return state;
    }
};

export default profileReducer;
