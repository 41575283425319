import { call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import i18n from '../../../../i18n';
import { updatePassword } from '../../shared/utils';
import {
    changePasswordError,
    changePasswordSuccess,
    resetPasswordError,
    resetPasswordSuccess,
    verifyEmailError,
    verifyEmailSuccess,
} from '../actions';
import * as types from '../constants/actionTypes';

function* resetPasswordCode(action) {
    try {
        yield call(Api.resetPasswordCode, action.payload);
        yield put(resetPasswordSuccess({ done: true, success: true }));
    } catch (e) {
        yield put(
            resetPasswordError(
                e?.response?.data?.error?.message ||
                    i18n.t('user_with_this_email_not_exists'),
            ),
        );
    }
}

function* verifyCode(action) {
    try {
        const response = yield call(Api.verifyCode, action.payload);
        yield put(
            verifyEmailSuccess({ ...response.data, done: true, success: true }),
        );
    } catch (e) {
        yield put(
            verifyEmailError(
                e?.response?.data?.error?.message ||
                    i18n.t('invalid_data_text'),
            ),
        );
    }
}

function* sendNewPassword(action) {
    try {
        yield call(updatePassword, action.payload);
        yield put(changePasswordSuccess({ done: true, success: true }));
    } catch (e) {
        yield put(
            changePasswordError(
                e?.response?.data?.error?.message ||
                    i18n.t('invalid_data_text'),
            ),
        );
    }
}

export function* watchResetPassword() {
    yield takeLatest(types.RESET_PASSWORD_START, resetPasswordCode);
}

export function* watchVerifyEmail() {
    yield takeLatest(types.VERIFY_EMAIL_START, verifyCode);
}

export function* watchSendNewPassword() {
    yield takeLatest(types.CHANGE_PASSWORD_START, sendNewPassword);
}
