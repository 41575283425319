import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    firstName: '',
    email: '',
    description: '',
    success: false,
};

const contactUsClear = (initialState) => ({ ...initialState });

const contactUsGetUserInfo = (state) => ({ ...state, done: false });

const contactUsSetUserInfo = (state, action) => ({
    ...state,
    done: true,
    firstName: action.payload.firstName,
    email: action.payload.email,
});

const contactUsSendMessageStart = (state, action) => ({
    ...state,
    description: action.payload.description,
    done: false,
    success: false,
});

const contactUsSendMessageFinish = (state, action) => ({
    ...state,
    done: true,
    firstName: action.payload.firstName,
    email: action.payload.email,
    description: '',
    success: !action.error,
});

export const contactUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONTACT_US_CLEAR:
            return contactUsClear(initialState);
        case types.CONTACT_US_GET_USER_INFO:
            return contactUsGetUserInfo(state);
        case types.CONTACT_US_SET_USER_INFO:
            return contactUsSetUserInfo(state, action);
        case types.CONTACT_US_SEND_MESSAGE_START:
            return contactUsSendMessageStart(state, action);
        case types.CONTACT_US_SEND_MESSAGE_FINISH:
            return contactUsSendMessageFinish(state, action);
        default:
            return state;
    }
};
