import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { loadState, saveState } from '../common/utils';
import cartMiddleware from '../config/cartMiddleware';
import { isAuthenticated } from '../features/login/shared/utils';
import rootReducer from './root.reducer';
import rootSaga from './root.saga';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
    process.env.NODE_ENV === 'production'
        ? compose
        : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();

const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(cartMiddleware, sagaMiddleware)),
);

store.subscribe(() => {
    saveState({
        session: {
            ...store.getState().session,
            isAuthenticated: isAuthenticated(
                store.getState().session.accessToken,
            ),
        },
        emailConfirm: {
            ...store.getState().emailConfirm,
            requestEmailBlocked: false,
        },
        orderResult: {
            orderId: store.getState().orderResult.orderId,
        },
        cart: {
            offlineCart: store.getState().cart.offlineCart,
            done: true,
            isCartEmpty: true,
            data: null,
        },
    });
});

sagaMiddleware.run(rootSaga);

export default store;
