import * as types from '../constants/actionTypes';

export const getProductRequest = (data) => ({
    type: types.GET_PRODUCT_REQUEST,
    payload: data,
});

export const getProduct = (data) => ({
    type: types.GET_PRODUCT,
    payload: data,
});

export const setProduct = (data) => ({
    type: types.SET_PRODUCT,
    payload: data,
});
