import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { ordersSet } from '../actions';
import * as types from '../constants/actionTypes';

function* getOrders(action) {
    try {
        const response = yield all([
            call(Api.getOrders, action.payload),
            call(Api.getOrders, 'history'),
        ]);

        const responseActiveOrders = response[0].data;
        const responseHistoryOrders = response[1].data;

        const orders = [responseActiveOrders, responseHistoryOrders];
        yield put(ordersSet(orders));
    } catch (e) {
        yield put(ordersSet([]));
    }
}

function* watchGetOrders() {
    yield takeLatest(types.ORDERS_GET, getOrders);
}

export default function* watchOrders() {
    yield fork(watchGetOrders);
}
