import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { setGoodsCategotyRefurbished, setGoodsRefurbished } from '../actions';
import * as types from '../constants/actionTypes';

const getState = (state) => ({
    goods: state['refurbished'].goods,
    goodsCategory: state['refurbished'].goodsCategory,
});

function* getGoodsCategoty({ payload }) {
    try {
        const [goodsCat, goods] = yield all([
            call(Api.getSubcategoriesRefurbished, payload),
            call(Api.getProductRefurbisheds, payload ?? {}),
        ]);

        yield put(setGoodsCategotyRefurbished(goodsCat.data.data));
        yield put(setGoodsRefurbished(goods.data));
    } catch (e) {
        //error
    }
}

function* getGoods({ payload }) {
    try {
        const { data } = yield call(Api.getProductRefurbisheds, payload ?? {});
        const { goods } = yield select(getState);

        const id = payload?.subcategoryId ?? null;
        const merged = !payload?.isNextPage
            ? data.data
            : goods.concat(data.data);

        yield put(setGoodsRefurbished({ ...data, data: merged, id }));
    } catch (e) {
        //error
    }
}

function* watchGoodsCategoryRefurbished() {
    yield takeLatest(types.GET_REQUEST_ALL_REFURBISHED, getGoodsCategoty);
}

function* watchGoodsRefurbished() {
    yield takeLatest(types.GET_GOODS_REFURBISHED, getGoods);
}

export default function* watchRefurbished() {
    yield all([
        fork(watchGoodsCategoryRefurbished),
        fork(watchGoodsRefurbished),
    ]);
}
