import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    margin: 0;
    background-color: #ffffff;
  }

  html {
    height: 100%;
    box-sizing: border-box;
    font-family: "Figtree", Arial, Helvetica, sans-serif;
  }

  button {
    font-family: "Figtree", Arial, Helvetica, sans-serif;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, font, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td {
    outline: 0;
    &:focus, &:active {
      outline: none;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  #root {
    height: 100%;
  }

  input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px #eeeef3 inset;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .expivi-single-product-container {
    display: table;
    height: 100%;
    width: 100%;
  }

  .expivi-single-product-container .expivi-viewer-container {
    display: table-cell;
    width: 64%;
    padding-right: 15px;
    vertical-align: top;
  }

  .expivi-single-product-container .expivi-option-container {
    height: 100%;
    display: table-cell;
    width: 36%;
    p {
      @media (max-width: 800px) {
        padding: 0 16px;
      }
    }
  }

  .viewer {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;

    @media (max-width: 980px) {
      height: 100%;
    }
  }

  #svg_renderdom {
    display: none;
  }
  
  @media (max-width: 980px) {
    .expivi-single-product-container {
      display: block;
    }

    .expivi-single-product-container .expivi-viewer-container {
      height: 40vh;
      display: block;
      width: 100%;
      padding-right: 0;
    }

    .expivi-single-product-container .expivi-option-container {
      height: initial;
      display: block;
      width: 100%;
    }
  }

  .loader-checkout {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
`;

export default GlobalStyle;
