import * as types from '../constants/actionTypes';

export const resetPasswordStart = (email) => ({
    type: types.RESET_PASSWORD_START,
    payload: email,
});

export const resetPasswordSuccess = (data) => ({
    type: types.RESET_PASSWORD_SUCCESS,
    payload: data,
});

export const resetPasswordError = (error) => ({
    type: types.RESET_PASSWORD_ERROR,
    error,
});

export const resetPasswordClearError = () => ({
    type: types.RESET_PASSWORD_ERROR,
    error: '',
});
