import * as types from '../constants/actionTypes';

const initialState = {
    done: true,
    error: '',
    personalInfoFormSaved: false,
    deliveryAddressFormSaved: false,
    user: {
        firstName: null,
        lastName: null,
        gender: null,
        dateOfBirthday: null,
        nationality: null,
        country: null,
        zipCode: null,
        city: null,
        address: null,
        countryCode: null,
        phoneNumber: null,
        profileImage: null,
    },
};

const profileGetUserStart = (state) => ({ ...state, done: false });

const profileGetUserSuccess = (state, action) => ({
    ...state,
    done: true,
    user: action.payload,
});

const profileGetUserError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
});

const profileUpdateUserStart = (state) => ({ ...state, done: false });

const profileUpdateUserSuccess = (state, action) => ({
    ...state,
    done: true,
    user: action.payload.data,
    personalInfoFormSaved: action.payload.formType === 'personalInfoForm',
    deliveryAddressFormSaved: action.payload.formType === 'deliveryAddressForm',
});

const profileUpdateUserError = (state, action) => ({
    ...state,
    done: true,
    error: action.error,
});

const profileClear = (initialState) => ({ ...initialState });

const profileResetForm = (state, action) => {
    return {
        ...state,
        [action.payload]: false,
    };
};

const profileSavePhotoSuccess = (state, action) => ({
    ...state,
    user: {
        ...state.user,
        profileImage: action.payload.profileImage,
    },
});

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PROFILE_CLEAR:
            return profileClear(initialState);
        case types.PROFILE_GET_USER_START:
            return profileGetUserStart(state);
        case types.PROFILE_GET_USER_SUCCESS:
            return profileGetUserSuccess(state, action);
        case types.PROFILE_GET_USER_ERROR:
            return profileGetUserError(state, action);
        case types.PROFILE_UPDATE_USER_START:
            return profileUpdateUserStart(state);
        case types.PROFILE_UPDATE_USER_SUCCESS:
            return profileUpdateUserSuccess(state, action);
        case types.PROFILE_UPDATE_USER_ERROR:
            return profileUpdateUserError(state, action);
        case types.PROFILE_RESET_FORM:
            return profileResetForm(state, action);
        case types.PROFILE_SAVE_PHOTO_SUCCESS:
            return profileSavePhotoSuccess(state, action);
        default:
            return state;
    }
};

export default profileReducer;
