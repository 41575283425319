import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../../../api';
import { setFaqItem } from '../actions';
import * as types from '../constants/actionTypes';

function* getFaq(action) {
    try {
        const response = yield call(Api.getFaqItem, action.payload);
        yield put(setFaqItem(response.data));
    } catch (e) {}
}

function* watchGetFaq() {
    yield takeLatest(types.GET_FAQ_ITEM, getFaq);
}

export default function* watchFaq() {
    yield all([fork(watchGetFaq)]);
}
